.slash {
  padding: 1.5rem 1.5rem calc(120px + 1.5rem);
  clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 120px), 0 100%);
}

.slash-nw {
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 120px 100%);
    padding: 1.5rem 1.5rem 1.5rem calc(120px + 1.5rem);
  }
}