.panel-switcher {

}

.panel-switcher-panels {
  //aspect-ratio: 4/5;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;  /* Adjust width as needed */
  //height: 100vh; /* Full viewport height */
  height: 0;

  @include media-breakpoint-up(lg) {
    //height: 70vh;
  }

  @media screen and (max-height: 650px) and (max-width: 400px) {
    //height: 120vh;
  }
}

.panel-switcher-panel {
  grid-column: 1 / -1; /* Make the element span the full width */
  grid-row: 1 / -1;    /* Make the element span the full height */
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut;
  transition: opacity 0.8s ease, visibility 0.8s ease; /* Transition for smooth fade */
}

.panel-switcher-panel.active {
  opacity: 1;
  visibility: visible;
  animation: fadeIn 0.8s ease; /* Apply fade-in animation */
}

.panel-switcher-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #979797;
    z-index: 1;
  }
}

.panel-switcher-buttons {
  border: 1px solid #979797;
  background-color: #fff;
  z-index: 2;
  display: flex;
  align-items: stretch;
}

.panel-switcher-button {
  color: #363636;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  letter-spacing: 2px;
  text-transform: uppercase;
  background: transparent;
  padding: 15px 30px;
  border: none;
  position: relative;
  border-radius: 0;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @include media-breakpoint-up(lg) {
    padding: 15px 50px;
  }
}

/* Button styles for active state */
.panel-switcher-button.active,
.panel-switcher-button:hover {
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 77px;
    height: 5px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #E1261C;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade-out animation */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
