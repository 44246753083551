$carouselTransitionCurve: cubic-bezier(0.165, 0.84, 0.44, 1);
$carouselTransitionDuration: 1400ms;

.manager-app-content,
.worker-app-content {
  @include media-breakpoint-up(lg) {
    margin-left: -15vw !important;
    margin-right: 0 !important;
  }

}

.manager-app-content {
  @include media-breakpoint-up(lg) {
  }

}

.worker-app-content {
  @include media-breakpoint-up(lg) {
    z-index: -1;
  }

}

.worker-app-content-alt {
  @include media-breakpoint-up(lg) {
    margin-right: 0 !important;
  }

}

.advantage-title {
  @include media-breakpoint-up(md) {
    min-height: 40px;
  }
  @include media-breakpoint-up(lg) {
    min-height: 65px;
  }
  @include media-breakpoint-up(xl) {
    min-height: 40px;
  }
}

