.facets-container {
    padding-top: 65px;
    padding-bottom: 15px;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    z-index: $zindex-modal;
    @include media-breakpoint-up(lg) {
        position: static;
        width: auto;
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
        z-index: 1;
        overflow: visible; 
    }
}

.facets-mobile-button-container {
    flex-basis: 50%;
    padding-right: 1rem;
}

.facets-mobile-button {
    background-color: $dark;
    color: #fff;
    border: 1px solid #000;
    width: 100%;
    max-width: 150px;
    font-size: .9rem;
}

.facets-mobile-close {
    border: none;
    background: none;
    width: 44px;
    height: 44px;
    color: $dark;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 1.5rem;
    &:hover, &:focus {
        color: $featured;
    }
}