.breadcrumb {
	margin: 1.25em auto 0;

	ol {
        list-style-type: none;
        padding: 0;
		margin: 0;
    }
    li {
        display: inline;
        font-size: .9em;
    }

    li:before {
        content: none;
        padding: 0 .25em 0 0;
    }

    li a {
        display: none;
    }

    a { color: $primary;}

    @include media-breakpoint-down(sm) {
        li:nth-last-child(2) {
            a {
                display: inline;
            }
            &:before {
                content: "\003c\0020";
            }
        }
        li:last-child {
            display: none;
        }
    }
    
    @include media-breakpoint-up(md) {
        margin: .85em 0 0 0;

        li {
            //padding-left: .25em;
            a {
                display: inline;
            }
            &:before {
                content: none;
            }
            & + li:before {
                content: "\003E\0020";
            }
        }
	}

    @include media-breakpoint-up(lg) {
        margin: 2em 0 0 0;
    }
}

.breadcrumb-back {
    @include media-breakpoint-up(md) {
        position: absolute;
    }
}