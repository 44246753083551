.hero {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(5, 20%);
  height: 600px;
  background-color: #ABABAB;
  max-width: 1680px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    margin-top: 10px;
    height: 700px;
  }

  > .hero-content {
    grid-area: 1 / 1 / span 5 / span 5;
    padding: 20px;

    @include media-breakpoint-up(lg) {
      grid-area: 1 / 1 / span 5 / span 3;
      padding: 100px 0 0 100px;
    }

    @include media-breakpoint-up(xxl) {
      grid-area: 1 / 1 / span 5 / span 2;
    }
  }

  > img {
    grid-area: 1 / 1 / span 5 / span 5;
  }

  img {
    width: 100%;
    height: 100%;
    min-height: 0;
    object-fit: cover;
    object-position: 85% bottom;

    @include media-breakpoint-up(md) {
      object-position: right bottom;
    }
  }

  &:after {
    content: "";
    grid-area: 1 / 1 / span 4 / span 5;
    //background: linear-gradient(to right, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 66%);
    //background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(152,152,152,0) 35%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.5) 100%);
    //background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(152,152,152,0) 46%, rgba(0,0,0,0.4710477941176471) 60%, rgba(0,0,0,0.5214679621848739) 100%);
    background: linear-gradient(337deg, rgba(255,255,255,0) 0%, rgba(152,152,152,0) 46%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,.6) 100%);
    height: 100%;
    max-height: 556px;
    @include media-breakpoint-down(sm) {
      grid-area: 1 / 1 / span 5 / span 5;
      //background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(152,152,152,0) 35%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.5) 100%);
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(152,152,152,0) 10%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0.7) 100%);
    }
  }
}

.hero-content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: left;
  padding: 1rem;

  > * {
    color: #fff;
    margin-bottom: 1rem;
  }
}

.simple-image-hero {
  max-width: 1200px;
  margin: 0 auto;
}
