.testimonial {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto;
    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-rows: auto auto;
    }
}

.testimonial__left {
    grid-column: 1;
    grid-row-start: 2;
    grid-row-end: 3;
    .testimonial__image-container {
        position: relative;
        &:before {
            content: url('#{$cdnPath}/img/gray-corner-plain-right.png');
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 12px;
            height: 29px;
        }
    }
    @include media-breakpoint-up(md) {
        grid-row-start: 1;
    }
}

.testimonial__title:before, .testimonial__end-quote:after {
    content: " ";
    background-image: url('#{$cdnPath}/img/quote.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 38px;
    height: 32px;
    margin-top: -10px;
    margin-left: 10px;
    margin-right: 10px;
    flex-shrink: 0;
}

.testimonial__title {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    display: flex;
    font-weight: 800;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
        grid-column: 2;
        margin-left: 25px;
    }
}

.testimonial__end-quote {

    &.large-text {
        @include media-breakpoint-up(xxl) {
            font-size: 1.25rem !important;
        }
    }

    &:after {
        display: inline-block;
        transform: rotate(180deg);
        position: relative;
        top: 10px;
        margin-right: 0;
    }
}

.testimonial__text {
    grid-column: 2;
    grid-row: 2;
    p {
        font-size: 1.1rem;
        line-height: 1.8;
        //account for CSS interpretation of line-height vs Sketch
        margin-top: -8px;
    }
    @include media-breakpoint-up(md) {
        margin-left: 67px; //align with title next to quote
    }
}

.testimonial__right {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    .testimonial__image-container {
        position: relative;
        &:before {
            content: url('#{$cdnPath}/img/gray-corner-plain-left.png');
            position: absolute;
            left: -1px;
            top: -1px;
            width: 12px;
            height: 29px;
        }
    }
}

.testimonial__image {
    width: 300%;
    margin-left: -100%;
    @include media-breakpoint-up(md) {
        width: 100%;
        margin: auto;
    }
}

.no-image-mobile {
    @include media-breakpoint-down(sm) {
        .testimonial {
            display: block;
        }

        .testimonial__left {
            display: none;
        }

        .testimonial__text {
            margin-left: 42px;
        }
    }
}

.testimonial--stacked-on-mobile {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        display: grid;
    }

    .testimonial__image {
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin: auto;
        }
    }
}

.testimonial--no-right-image {
    .testimonial__title,
    .testimonial__text {
        @include media-breakpoint-up(md) {
            grid-column-start: 2;
            grid-column-end: -1;
        }
    }
}

.testimonial-grid {
    &__item-container {
        display: none;
        margin-bottom: 32px;
    }
    &__item {
        border: 1px solid #979797;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
    }
    &__item-name,
    &__item-location {
        text-transform: capitalize;
    }
    &__item-body {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        line-height: 1.6rem;

        > * {
            margin: 0;
            padding: 0;
            font-size: 1rem;
            line-height: 1.6rem;
        }
    }
    &__image {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: contain;
        max-height: 600px;
    }
    .quote-featured {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 28.8px */
        letter-spacing: 1.2px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        padding: 10vh 0;
        background-image: url('#{$cdnPath}/img/testimonials/bg-testimonial-grid.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        cite {
            font-family: Lato, sans-serif;
            font-size: 18px;
            font-style: normal;
            line-height: 160%; /* 28.8px */
            text-transform: none;
            margin-top: 20px;
            font-weight: 400;
            span {
                font-weight: 700;
            }
        }
    }
    &__actions {
        gap: 20px;
    }
    &__pull_quote {
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .testimonial-grid-pull-quote {
        margin-top: -20px;
        z-index: -1;
    }
    //.display-8 {
    //    .testimonial-grid__item-container:nth-of-type(3),
    //    .testimonial-grid__item-container:nth-of-type(4) {
    //        margin-bottom: 40vh;
    //    }
    //}
    //.display-16 {
    //    .testimonial-grid__item-container:nth-of-type(7),
    //    .testimonial-grid__item-container:nth-of-type(8) {
    //        margin-bottom: 40vh;
    //    }
    //}
    //.display-24 {
    //    .testimonial-grid__item-container:nth-of-type(11),
    //    .testimonial-grid__item-container:nth-of-type(12) {
    //        margin-bottom: 40vh;
    //    }
    //}
}

.stacked {
    .testimonial-grid {
        &__item-container {
            @include media-breakpoint-up(md) {
                max-width: 80%;
                margin: 0 auto 32px;
            }
        }
    }
}

.testimonials-page {
    //.btn-pager:not(.active) {
    //    display: none;
    //    @include media-breakpoint-up(md) {
    //        display: block;
    //    }
    //}
    .btn-pager {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    cite {
        font-style: normal;
    }

    .view-options {
        input {
            display: none;
        }
        label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 20px;
            border: 1px solid #979797;

            &:hover {
                cursor: pointer;
            }

            &:after {
                content: '';
                width: 30px;
                height: 4px;
                background: #979797;
            }
            &.for-stacked {

            }
            &.for-grid {
             &:after {
                 background: linear-gradient(
                     to right,
                     #979797,
                     #979797 40%,
                     transparent 40%,
                     transparent 60%,
                     #979797 60%,
                     #979797 100%,
                 );
             }
            }
        }
        input:checked+label {
            background: #363636;
            border-color: #363636;

            &:after {
                background: #fff;
            }
            &.for-grid {
                &:after {
                    background: linear-gradient(
                        to right,
                        #F8F8F8,
                        #F8F8F8 40%,
                        #363636 40%,
                        #363636 60%,
                        #F8F8F8 60%,
                        #F8F8F8 100%,
                    );
                }
            }
        }
    }
    select:invalid {
        color: #9E9E9E;
        font-style: italic;
    }
}