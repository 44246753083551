.hero-nav {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        /* Make sure it doesn't run into the angle on the right */
        padding-right: 25px;
        padding-left: 25px;
    }
}

.hero-nav__item {
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 61px;
        border-top: 1px solid $border-color;
        padding: 10px 56px;
        text-align: center;
        text-transform: uppercase;
        font-size: .9rem;
        line-height: 1.1;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        background-color: $dark;
        background-size: auto 120px;
        background-position: top right;
        color: #fff;
        text-decoration: none;
        &:hover, &:focus {
            background-color: $gray;
            z-index: 500;
        }
        &:not(:hover) {
            /* So inline background image only shows on hover */
            background-image: none !important;
        }
        /* Puts the text above the background image and overlay on hover */
        span {
            position: relative;
            z-index: 1;
        }


        @include media-breakpoint-down(md) {
            &:hover {
                background-image: none !important;
                background-color: #000;
            }
        }
        @include media-breakpoint-up(lg) {
            background-color: rgba(0,0,0,.5);
            border: 1px solid $border-color;
            padding: 10px 24px;
            @supports (backdrop-filter: blur(5px)) {
                backdrop-filter: blur(5px);
                background-color: transparent;
            }
            &:hover {
                background-color: transparent;
                &:after {
                    /* Overlay over hover image */
                    content: ' ';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: rgba(0,0,0,.3);
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        width: 210px;
        height: 80px;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 15px;
        a {
            position: absolute;
            width: 200px;
            height: 80px;
            margin-top: 0;
            transition: height .2s, margin-top .2s, clip-path .2s;
            &:hover, &:focus {
                height: 120px;
                margin-top: -20px;
            }
        }
    }
    /* Create angle notch in last item */
    &:last-child {
        a {
            &:before {
                position: absolute;
                bottom: -1px;
                right: -1px;
                content: url('#{$cdnPath}/img/hero-nav-angle-mobile.gif');
                width: 21px;
                height: 31px;
            }
        }
        @include media-breakpoint-up(lg) {
            a {
                &:before {
                    content: url('#{$cdnPath}/img/hero-nav-angle.gif');
                }
                clip-path: polygon(0px 0px, 200px 0px, 200px 50px, 180px 80px, 0px 80px);
                &:hover, &:focus {
                    clip-path: polygon(0px 0px, 200px 0px, 200px 90px, 180px 120px, 0px 120px);
                }
            }
        }
    }
}

.hero-nav__item--safes {
    a {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &:hover, &:focus {
            background-color: #000;
        }
    }
}