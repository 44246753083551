//Grid templates specific to different sections on Lockout Tagout
//These values need to align between the sections
$small-column-width: 130px;
$large-column-width: $small-column-width * 3;
$regular-column-width: 259px;

/* For the first column group, we need a special material heading that spans multiple columns */
.grid-loto-heading {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row: 1;
}

.grid-loto-image {
    max-width: 115px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.grid-loto-material {
    img {
        margin-bottom: 15px;
    }
    @include media-breakpoint-up(xl) {
        @supports (display: grid) {
            display: grid;
            grid-template-columns: $large-column-width $regular-column-width $regular-column-width;
            grid-template-rows: repeat(3, auto);
            //Fill each column in turn
            grid-auto-flow: column;
        }
    }
}

.grid-loto-shackle {
    @include media-breakpoint-down(md) {
        @supports (display: grid) {
            display: grid;
            grid-template-columns: $small-column-width $small-column-width;
            grid-template-rows: repeat(25, auto);
            grid-auto-flow: column;
            justify-content: center;
            .grid-loto-heading {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row: 1;
            }
            .grid-sm-left {
                grid-column: 1;
            }
            .grid-sm-right {
                grid-column: 2;
            }
            .grid-sm-double {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(3, $small-column-width) $regular-column-width $regular-column-width;
            grid-template-rows: repeat(5, auto);
            grid-auto-flow: column;
        }
        
    }
}

.grid-loto-one-row {
    @include media-breakpoint-down(md) {
        @supports (display: grid) {
            display: grid;
            grid-template-columns: $small-column-width $small-column-width;
            grid-template-rows: repeat(13, auto);
            grid-auto-flow: column;
            justify-content: center;
            .grid-loto-heading {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row: 1;
            }
            .grid-sm-left {
                grid-column: 1;
            }
            .grid-sm-right {
                grid-column: 2;
            }
            .grid-sm-double {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(3, $small-column-width) $regular-column-width $regular-column-width;
            grid-template-rows: auto;
            grid-auto-flow: column;
        }
        
    }
}