.promos {
    padding: 15px;
    position: relative;
    @include media-breakpoint-up(lg) {
        padding: 15px 0;
    }
}

.promos--background {
    //&:before {
    //    content: " ";
    //    background-color: $primary;
    //    position: absolute;
    //    left: 0;
    //    right: 0;
    //    top: 0;
    //    margin-top: 30px;
    //    height: 600px;
    //    @include media-breakpoint-up(lg) {
    //        margin-top: 80px;
    //        height: 450px;
    //    }
    //    @include media-breakpoint-up(xxl) {
    //        height: 500px;
    //    }
    //}
}

.promo-featured {
    @include animate-left;
    display: block;
    position: relative;
    background-color: #fff;
    border: 1px solid #ABABAB;
    padding-top: 30px;
    padding-bottom: 30px;
    @include media-breakpoint-up(lg) {
        border: none;
        background: transparent;
        display: flex;
        //width: 90%;
    }
}

.promo-featured-section {
    @include media-breakpoint-up(xl) {
        flex: 1 1 auto;
    }

}


.promo-featured-middle {
    @include media-breakpoint-up(lg) {
        background-color: #fff;
        border-top: 1px solid #ABABAB;
        border-bottom: 1px solid #ABABAB;
    }
}

.promo-featured-left {
    @include media-breakpoint-up(lg) {
        background-color: #fff;
        border-top: 1px solid #ABABAB;
        border-bottom: 1px solid #ABABAB;
        border-left: 1px solid #ABABAB;
    }
}

.promo-featured-right {
    @include media-breakpoint-up(lg) {
        background-color: #fff;
        border-top: 1px solid #ABABAB;
        border-bottom: 1px solid #ABABAB;
        border-right: 1px solid #ABABAB;
    }
}

.promo-featured__image {
    display: block;
    position: relative;
    top: -60px;
    margin-bottom: -30px;
    @include media-breakpoint-up(lg) {
        top: -60px;
        margin-bottom: -60px;
     }
}

.promo .card-text {
    margin-bottom: 0;
    line-height: 1.375;
}

.promo .card-title {
  @media screen and (max-width: 413px) {
    font-size: 1.15rem;
  }
}

.promo:hover .link-style {
  color: #7a1919;
  text-decoration: underline;
}

.promo[data-animate=promo] {
    @include animate-right;
    transition-delay: 200ms;
}

.promo-banner {

    img {
        max-height: 45px;
        margin-bottom: 6px;

        @include media-breakpoint-down(sm) {
            max-height: 25px;
        }
    }

    .img-large {
      height: 70px;
      width: 70px;
      max-height: 70px;
      max-width: 70px;
      margin: 0;

      @include media-breakpoint-down(sm) {
        max-height: none;
      }
    }

    .img-xl {
      height: 80px;
      width: 140px;
      max-height: 80px;
      max-width: 140px;
      margin: 0;

      @include media-breakpoint-down(md) {
        height: 160px;
        width: 280px;
        max-height: 160px;
        max-width: 280px;
      }

      @include media-breakpoint-down(sm) {
        height: auto;
        width: auto;
        max-height: none;
        max-width: 100%;
      }
    }

    .promo-title {
        font-size: 1.25rem;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
            font-size: .9rem;
        }
    }
}

.promo-text-red {
    color: #B92626;
}
.promo-text-white {
     color: #FFFFFF;
 }
.promo-text-black {
    color: #000000;
}
