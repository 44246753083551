.section-large-icon {
  background-color: #F8F8F8;
  background-repeat: no-repeat;
  min-height: 550px;
  background-position: -300px bottom;
  background-size: 600px;

  @include media-breakpoint-up(sm) {
    min-height: 600px;
    background-position: -150px bottom;
    background-size: 600px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 810px;
    background-position: -200px bottom;
    background-size: 800px;
  }

  &.icon-no-mobile {
    @include media-breakpoint-down(sm) {
      background: none!important;
    }
  }

  .page-title {
    @include media-breakpoint-up(sm) {
      font-size: 3rem;
    }
  }
}

// v2024
.ps-hero {
  &.psHeroHome {
    background-color: $gray-900;
    color: #fff;
  }

  &.psHeroDefault {

  }
}

.ps-hero-bg-slant {
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ps-app-title {
  font-size: 18px;
  font-weight: 800;
  line-height: 29.26px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    font-size: 24px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 40px;
    line-height: 52px;
  }
}

.ps-question-title {
  font-size: 22px;
  line-height: 26px;
  text-transform: none;
  letter-spacing: normal;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    line-height: 52px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 65%;
    margin: 0 auto;
  }
}


.ps-question-safety {
  font-size: 18px;
  line-height: 26px;
  text-transform: none;
  letter-spacing: normal;

  @include media-breakpoint-up(md) {
    font-size: 24px;
    line-height: 28.8px;
  }

  @include media-breakpoint-up(xl) {
    margin: 0 auto;
  }
}

.ps-progress-item {
  background-color: rgba(#B92626, .25);
  border-radius: 8px;

  &.active {
    background-color: rgba(#B92626, 1);
  }
}

.ps-option {
  min-height: 150px;
  border: 1px solid #ABABAB;
  background-color: #FFF;
  margin: 0;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  input[type="radio"] {
    //visibility: hidden;
    appearance: none;
    width: 28px;
    height: 28px;
    background: $primary;
    border-radius: 3px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #FFF;
      border-radius: 2px;
    }
  }

  &.active {
    border-color: #B92626;
    border-width: 2px;

    input[type="radio"] {
      &:after {
        width: 18px;
        height: 18px;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.disabled {
    opacity: .5;
    background-color: #EDEDED;
    border-color: #EDEDED;

    &:hover {
      cursor: default;
    }

    input[type="radio"] {
      &:after {
        background-color: #EDEDED;
      }
    }
  }
}

.ps-option-title {
  text-transform: none;
  letter-spacing: normal;

  @include media-breakpoint-up(md) {
    //font-size: 22px;
    //line-height: 26px;
  }
}

.ps-option-description {

}

.ps-option-img {
  aspect-ratio: 13 / 15;
  //background: #00a6c9;
  width: 25%;
  flex-shrink: 0;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.ps-tip-container {
  border: 1px solid #ABABAB;
}

.ps-tip-text {
  font-size: 18px;
  line-height: 26px;
}

.ps-tip-img {
  margin-top: -2.4rem;
  width: 50%;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    width: 100%;
  }
}

.ps-dead-end-msg {
  h2 {
    text-transform: none;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }
}

.ps-breadcrumb {
  text-align: center;
  flex-wrap: wrap;
  li {
    font-family: $headings-font-family;
    font-size: 16px;
    line-height: 1.4rem;
    font-weight: 700;
    color: $body-color !important;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 1.8rem;
    }
  }
  li:after {
      padding: 0 5px;
  }
  li:not(:last-child):after {
    //content: "\2192"; // arrow
    content: "\2022"; // bullet
    //content: "\003E"; // gt arrow
    //content: "\002f"; // slash
    //content: "\007c"; // pipe
  }
}
