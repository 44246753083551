$product-colors: (
    "black": #000,
    "blue": #1c4888,
    "brown": #8c6239,
    "cream": #b8c3c5,
    "darkblue": #2c56de,
    "gold": #c8b08a,
    "gray": #8c8e8d,
    "green": #0b9750,
    "orange": #c24121,
    "pink": #cc769d,
    "purple": #969,
    "red": #a90d18,
    "silver": #c4c3c1,
    "teal": #366,
    "white": #fff,
    "yellow": #eeca58,
    "base": transparent

);

/* If the color doesn't exist */
[data-color] {
    &, &:hover {
        color: #666;
    }
}

@each $name, $hex in $product-colors {
    [data-color=#{$name}] {
        &, &:hover {
            color: $hex;
        }
    }
}

.color-button {
    display: block;
    position: relative;
    background-color: currentColor;
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;

    &:focus, &:hover, &[disabled], &.current {
        outline: none;
        &:after {
            content: " ";
            width: 38px;
            height: 38px;
            border: 2px solid currentColor;
            position: absolute;
            left: -5px;
            top: -5px;
            border-radius: 50%;
        }
    }

    &.inactive {
        opacity: .2;
        cursor: not-allowed;

        &:focus:after, &:hover:after {
            display: none;
        }
    }

    /*&[data-color=assorted]  {
      border: 1px solid #000;
      background-image: url("/img/icon-ryb.jpg");
      background-repeat: no-repeat;
      background-size: 100%;

      &:after {
        border-color: #000;
      }
    }*/

    &[data-color=white],  {
        border: 1px solid #666;

        &:after {
           border-color: #ccc;
        }
    }

    &[data-color=base],  {
        position: relative;
        border: 1px solid #666;

        &:before {
            font-family: arial;
            content: "x";
            position: absolute;
            top: 3px;
            left: 9px;
            font-size: 20px;
            line-height: 20px;
            color: #888;
        }

        &:after {
            border-color: #888;
        }
    }
}

.color-button-container {
    max-width: 220px;
    display: flex;
    flex-wrap: wrap;
} 

.color-dot {
    display: inline-block;
    background-color: currentColor;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: 3px;

    &[data-color=white] {
        border: 1px solid #999;
    }

    /*&[data-color=assorted]  {
      border: 1px solid #000;
      background-image: url("/img/icon-ryb.jpg");
      background-repeat: no-repeat;
      background-size: 100%;
    }*/
}

