.one-hundred-years, .about-us {

  .landing-hero__video {
    position: relative;
    overflow: hidden;

    .bg-video {
      max-width: 100%;
    }

    .hero-heading {
      position: absolute;
      top: 0;

      .page-title {
        text-shadow: 4px 4px 5px rgba(0,0,0,.5);

        @include media-breakpoint-down(sm) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .icon-video {
    background-size: 100%;

    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 26px;
    }
  }

  .modal-body {
    height: calc(100vh - 38px);
  }

  .modal-body video {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-height: 768px) {
      width: auto;
      max-height: 620px;
    }

    @include media-breakpoint-up(md) {
      max-width: 85%;
    }
  }

  .logo-100 {
    width: 100%;
    max-width: 256px;
  }

  .page-nav {
    .nav-item {
      position: relative;
      color: $link-color;

      span:after {
        opacity: 0;
        content: ' ';
        width: 100%;
        position: absolute;
        border-bottom: 4px solid $link-hover-color;
        bottom: 0;
        z-index: 2;
        left: 0;
        transition: opacity .5s;
      }

      &:hover {
        text-decoration: none;
        color: $link-hover-color;

        span:after {
          opacity: 1;
        }
      }
    }
  }

  .community-champions .landing-hero:after {
    display: none!important;
  }

  .landing-hero .landing-hero__gradient.about-us  {
    @include media-breakpoint-up(sm) {
      background: linear-gradient(to right, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 66%);
    }
  }

  .section-community {
    @media only screen and (max-width: 1023px) {
      background: linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .3) 100%);
    }
  }

  .section-product {
    @include media-breakpoint-up(xxl) {
      margin-top: -7rem;
    }

    .container {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    .landing-card {
      @include media-breakpoint-down(sm) {
        margin: 0 auto;
        padding: 25px 0;
      }
    }

    .landing-card__image {
      width: 228px;
      height: 378px;
      margin: 0 auto;
      flex: none;

      @include media-breakpoint-up(xl) {
        width: 210px;
        height: 330px;
      }

      @include media-breakpoint-up(xxl) {
        width: 248px;
        height: 398px;
      }

      &:after {
        display: none;
      }
    }

    .landing-card__content {
      width: 228px;
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto;

      &:after {
        display: none;
      }

      &.align-buttons {
        height: 100%;
      }

      @include media-breakpoint-up(xl) {
        width: auto;
        margin: 0;
        padding-left: 2rem;
        padding-right: 2rem;

        &.align-buttons {
          height: 85%;
        }
      }

      @include media-breakpoint-up(xxl) {
        padding-left: 3rem;
        padding-right: 5rem;

        &.align-buttons {
          height: 75%;
        }
      }
      @include media-breakpoint-down(md) {
        .h3 {
          font-size: 1.25rem;
        }
      }

    }
  }

  .content img {
    max-width: 100%;
  }

  .landing-grid .content {
      @include media-breakpoint-up(xxl) {
      max-width: none;
    }
  }

  @media (min-width: 1410px) and (min-width: 992px) {
    .landing-grid--white-locks-text .columns {
      padding-left: 15px;
    }
  }

  .categories__button {
    padding: 32px 15px 26px 15px;
  }

  .product-category-title {

    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
  }

  .product-category-grid {
    height: auto;
  }

  .product-category {

    &[data-category-open=true] {
      height: auto;
      @include media-breakpoint-up(lg) {
        height: 600px;
      }

      @include media-breakpoint-up(lg) {
        .in-view.categories-animation & {
          height: 600px;
        }
      }
    }

    .col-3 {

      .product-category-product img {
          max-width: 92%;
          max-height: 360px;
      }

      .product-category-product.timeline-clickable {
        .product-category-name {
          text-decoration: underline;
        }
        img {
          transition: max-width 200ms ease-in;
        }
        &:hover {
          cursor: pointer;

          img {
            max-width: 93%;
          }
        }
      }

      .product-category-name {
        font-size: .875rem;
        max-width: 80%;
        margin: 1rem auto;

        @include media-breakpoint-down(md) {
          padding-bottom: 70px;

          &:after {
            bottom: 0;
          }
        }
      }

      //No hover state
      @include media-breakpoint-up(lg) {

        &:hover, &:focus {
          .product-category-product {
            background-color: transparent;
            display: block;

            .product-category-name {
              display: inherit;
            }
          }
        }
      }
    }
  }

  /*.testimonial__image-container {
    &:before {
      display: none;
    }
    img {
      max-width: 50%;
    }
  }*/
}

.hundred-celebration-social-icons {
  background: url(#{$cdnPath}/img/100-years/master-lock-social-media-icons-sm.png);
  background-repeat: no-repeat;
  width: 44px;
  height: 47px;

  &:hover {
    filter: brightness(70%);
  }

  &.twitter {
    background-position-x: -67px;
  }

  &.linkedin {
    background-position-x: -135px;
  }

  &.youtube {
    background-position-x: -203px;
  }

  &.instagram {
    background-position-x: -274px;
  }

}