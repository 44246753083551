/* This is ONLY for the grid layout of product detail, style components in the grid elsewhere */
.product-detail-grid {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-areas:
        "image info options"
        "image features features";
        grid-template-columns: 1.25fr 1fr 1fr;
        grid-template-rows: fit-content(100%) 1fr;
        column-gap: 40px;
        row-gap: 20px;
        margin: 0;

        @include media-breakpoint-up(md) {
            margin: 0;
        }

        .product-detail-grid__image {
            grid-area: image;
            display: flex;
            flex-direction: column;
        }

        .product-detail-grid__thumbs {
            grid-column: 2;
            grid-row: 2;
        }

        .product-detail-grid__name {
            grid-column: 2;
            grid-row: 1;
        }

        .product-detail-grid__options {
            grid-area: options;
        }

        .product-detail-grid__features {
            grid-area: features;
        }
    }
}

