$ml-clip-slash-angle-end: polygon(0 0, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
$ml-clip-slash-angle-start: polygon(10px 0, 100% 0%, 100% 100%, 0 100%);

.flags {
  position: absolute;
  top: 15px;
  left: -10px;
  display: flex;
  z-index: 90;
  filter: drop-shadow( 4px 4px 3px rgba(0, 0, 0, .15));
}

.flag {
  display: block;
  position: relative;
  background-color: $dark;
  clip-path: $ml-clip-slash-angle-end;
  color: $white;
  //width: 145px;
  //height: 34px;

  svg {
    filter: drop-shadow( 4px 4px 3px rgba(0, 0, 0, .15));
    fill: #e1261c;
    fill-rule: evenodd;
  }
  .flag-text {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: .8rem;
    padding: 0 20px 0 16px;
    line-height: 31px;
    text-transform: uppercase;
  }

  &-red {
    background-color: $primary;
  }

  &-yellow {
    background-color: $featured;
    color: #1E1E1E;
    font-weight: bold;
  }
  &-secondary-alt {
    background-color: $secondaryAlt;
    color: $white;
  }
}

.flag:not(:first-child) {
  clip-path: $ml-clip-slash-angle-start;
  margin-left: -10px;
  z-index: -1;
}

.flag:last-child {
  .flag-text {
    padding-right: 16px;
  }
}

.flag:first-child {
  .flag-text {
    padding-right: 21px;
  }
}

.flags-inline {
  position: static;
  filter: none;

  .flag-text {
    line-height: 27px;
  }
}