// control groups of business logic-controlled items so that the vertical spacing is consistent regardless of how many things are shown/hidden
.product-detail-content-group {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1.875rem;

    .product-detail-content-group-sub {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0.625rem;
    }

    .product-detail-content-group-sub--condensed {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0.3125rem;
    }
}

.product-detail-thumb {
    width: 70px;
    display: inline-block;
    margin-top: 15px;
    border: none;
    background-color: #fff;;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,.4);
    padding: 0;
    cursor: pointer;
    overflow: hidden;

    @media only screen and (max-width: 320px) {
        width: 50px;
        height: 50px;
    }

    .product-detail-thumb-img {
        display: block;
        height: 70px;
        margin: auto;

        @media only screen and (max-width: 320px) {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &.is-active {
        outline: 1px solid $primary;
    }
}

.product-thumbnails-grid {
    display: flex;
    justify-content: start;
    gap: 4%;
    flex-wrap: wrap;

    .product-thumbnails-grid-item {

    }
}

.product-detail-image.js-360-image {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &.is-active {
        visibility: visible;
        position: relative;
    }
}

.product-detail-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //padding: 15px;
    overflow: hidden;
    aspect-ratio: 1 / 1;

    //@media only screen and (max-width: 320px) {
    //    width: 200px;
    //    height: 200px;
    //}
    //
    //@include media-breakpoint-up(sm) {
    //    width: 407px;
    //    height: 407px;
    //}
    //
    //@include media-breakpoint-up(md) {
    //    width: 493px;
    //    height: 493px;
    //    padding: 70px;
    //}

    //#bigBoxImage {
    //    max-width: 100%;
    //    max-height: 238px;
    //
    //    @media only screen and (max-width: 320px) {
    //        max-height: 195px;
    //    }
    //
    //    @include media-breakpoint-up(sm) {
    //        max-height: 375px;
    //    }
    //
    //    @include media-breakpoint-up(md) {
    //        max-height: 350px;
    //    }
    //}

    img {
        object-fit: contain;
        width: 100%;
        max-width: 85%;
    }
}

.product-features-list {
    li {
        font-size: 16px;
        line-height: 150%; /* 22.4px */
    }
}

.video-download-modal-container {
    max-width: 540px;
    padding: 10px;
    font-size: .9rem;
}

#product-features li {
    line-height: 1.3;
}

.carousel-pdp {
    svg {
        transition: color 250ms ease-in-out;
    }
    .glide__slide {
        height: auto;
        .feature-card {
            height: 100%;
        }
    }
    .glide__arrows [data-glide-dir]:hover {
        svg {
            cursor: pointer;
            color: $primaryAlt;
        }
    }
}

.top-protection-safes {
    position: relative;
    //background-image: url(#{$cdnPath}/img/product/detail/top-protection-bg-safe-mobile_2x.jpg);
    //background-repeat: no-repeat;
    //background-size: 100%;
    //background-position: top;

    @include media-breakpoint-up(lg) {
        //background-image: url(#{$cdnPath}/img/product/detail/top-protection-bg-safe-desktop_2x.jpg);
        //background-size: cover;
        //background-position: right bottom;
    }
}

.top-protection-safes-content {
    margin-top: 100%;

    @include media-breakpoint-up(sm) {
        margin-top: 115%;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

.top-protection-safes-bg {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: center bottom;
    top: 0;
    left: 0;
    z-index: -1;

    @include media-breakpoint-up(lg) {
        object-position: right center;
        object-fit: cover;
        height: 100%;
    }
}

.top-protection-safes-screen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(54,54,54,1) 0%, rgba(54,54,54,0) 40%, rgba(54,54,54,0) 100%);
    opacity: 0.7;
}

.product-view-switcher {

}

.product-view-switcher-image-container {
    padding-bottom: 30px;
}

.product-view-switcher-image {
    width: 100%;
    object-fit: cover;
    object-position: center 70%;
    aspect-ratio: 6 / 4.25;


    &:not(:first-child) {
        display: none;
    }
}

.product-view-switcher-actions {
    display: flex;
    justify-content: center;
    gap: 24px;

    button {
        &.active {

        }
    }
}
//.buy-now-coupon {
//  max-width: 365px;
//}