.promo-bar {
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  border: 0.5px solid #9E9E9E;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 16px 45px 16px 24px;
  flex-direction: column;
  gap: 24px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
    padding: 16px 24px 16px 24px;
  }

  .no-close & {
    padding: 16px 24px;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @include media-breakpoint-up(lg) {
      justify-content: center;
    }

    //> * {
    //  margin: 0;
    //  padding: 0;
    //  display: flex;
    //  align-items: center;
    //}
  }

  //&:hover {
  //  color: #fff;
  //  text-decoration: none;
  //}

  .promo-bar-body {
    flex-direction: column;

    h2 {
      font-size: 16px;
      line-height: 26px;
      font-size: 20px;
      margin: 0 0 0 0;
      @include media-breakpoint-up(lg) {
        line-height: 26px;
        font-size: 20px;
        margin: 0;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 12px;
      margin: 12px 0 0 0;
      font-family: $headings-font-family;
      @include media-breakpoint-up(lg) {
        font-weight: 300;
        line-height: 28px;
        margin-top: 0px;
        margin-left: 10px;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      //padding: 15px;
    }
  }

  .promo-bar-cta {
    background-color: #B92626;
    clip-path: polygon(21px 0, 100% 0%, 100% 100%, 0 100%);
    padding: 0 15px 0 30px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: $headings-font-family;
    letter-spacing: 0.5px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .promo-bar-actions {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  .promo-bar-close {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    &:hover {
      cursor: pointer;
    }

    @include media-breakpoint-up(lg) {
      position: static;
    }


  }
}