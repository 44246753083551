@mixin add-shadow {
    content: url(#{$cdnPath}/img/shadow.png);
    position: absolute;
    bottom: -75px;
    left: 50%;
    margin-left: -100px;
}

#heroLanding {
    background-color: #fff;
    @include media-breakpoint-down(md) {
        width: 100%;
        position: relative;
        margin-top: 0;
        z-index: 5;
        min-height: 100vh;
    }
}