.brand-vision {
    @include media-breakpoint-up(md) {
        background-image: url(#{$cdnPath}/img/ghosted-logo.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.brand-vision__copy {
    @include media-breakpoint-down(sm) {
        font-size: 1.7rem;
    }
}