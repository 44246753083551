.link-box {
    position: relative;
    &:after {
        content: url("#{$cdnPath}/img/gray-corner.png");
        position: absolute;
        right: -1px;
        bottom: -3px;
        width: 14px;
        height: 36px;
    }
}