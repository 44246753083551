.raised-box {
    border: 1px solid $border-color;
    box-shadow: 6px 4px 6px 0 rgba(0,0,0,.15);
    background-color: #fff;
    @include media-breakpoint-up(xl) {
        background: transparent;
        border: none;
        box-shadow: none;
    }
}

.raised-box-image {
    width: 75px;
    height: 75px;
}

.raised-box-section {
    @include media-breakpoint-up(xl) {
        flex: 1 1 auto;
        height: 107px;
    }
}

.raised-box-side {
    @include media-breakpoint-up(xl) {
        background-color: #fff;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        box-shadow: 6px 4px 6px 0 rgba(0,0,0,.15);
        position: relative;
        z-index: 1;
    }
}

.raised-box-middle {
    width: 100%;
    @include media-breakpoint-up(xl) {
        width: 90%;
        background-color: #fff;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        box-shadow: 6px 4px 6px 0 rgba(0,0,0,.15);
        position: relative;
        z-index: 2;
        &:after {
            content: url(#{$cdnPath}/img/raised-box-angle.png);
            height: 120px;
            position: absolute;
            top: -1px;
            right: -49px;
        }
    }
}