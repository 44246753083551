.card-flag {
    position: absolute;
    top: 15px;
    left: -10px;
    display: block;
    width: 145px;
    height: 34px;

    svg {
        filter: drop-shadow( 4px 4px 3px rgba(0, 0, 0, .15));
        position: absolute;
        top: 0;
        left: 0;
    }

    .card-flag-text {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: .85rem;
        position: absolute;
        left: 0;
        z-index: 1;
        padding: 0 15px;
        line-height: 34px;
        text-transform: uppercase;
    }

    .product-detail-grid__image & {
        z-index: 2;
        top: 35px;

        @include media-breakpoint-down(md) {
            top: 30px;
            left: 0;
        }
    }
}