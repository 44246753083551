.card {
    &.card-angle {
        width: 100%;
        background-size: cover;
        background-position: center top;
        position: relative;
        flex-grow: 1;
        flex-shrink: 0;
        &:after {
            content: url('#{$cdnPath}/img/gray-corner-border-right.png');
            width: 12px;
            height: 29px;
            position: absolute;
            bottom: -1px;
            right: -1px;
        }
    }
}

.card-img-top-container  {
    .card-img-top--locked-ratio {
        object-fit: cover;
        height: 100%;
        width: 100%;
        aspect-ratio: 89/51;
    }
}

.card-meta {
    font-size: 14px;
    font-style: italic;
}