//Indicator shows your horizontal scroll progress on mobile

.scroll-section-indicator {
    display: block;
    position: absolute;
    width: 200px;
    left: 50%;
    bottom: 25px;
    margin-left: -100px;
    background-color: #a1a1a1;
    height: 3px;
}

.scroll-section-indicator__progress {
    position: absolute;
    height: 3px;
    width: 50px;
    background-color: $primary;
    left: 0;
}

.scroll-section {
    @include media-breakpoint-down(md) {
        min-width: 960px;
    }
}

.scroll-section--thin {
    @include media-breakpoint-down(md) {
        min-width: 768px;
    }
}

.scroll-section--wide {
    @include media-breakpoint-down(md) {
        min-width: 1280px;
    }
}

.scroll-section-container {
    width: 100%;
    height: 105%;
    overflow-x: scroll;

    @include media-breakpoint-up(lg) {
        overflow: hidden;
        height: calc(100% - 115px);
    }

    &.tall-section {
        @include media-breakpoint-up(lg) {
            overflow: hidden;
            height: calc(100% - 45px);
        }
    }
}

.scroll-section-container::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0;
}

.scroll-overflow {
    overflow: hidden;
}