.smart-feature-list {
  display: flex;
  flex-direction: column;
  //gap: 30px;
  > div {
    border-bottom: 1px solid black;
    padding: 2rem;
  }
  > div:last-of-type {
    border-bottom: none;
  }
  h3, p {
    margin: 0;
    padding: 0;
  }
}
.compare-header {
  @include media-breakpoint-down(md) {
    padding-left: 5px;
    padding-right: 5px;
  }
 h3 {
   @include media-breakpoint-down(md) {
     font-size: 20px
   }
 }
  img {
    border-radius: 10px;
  }
}
.compare-header-item {
  background-color: #F2F2F2;
  gap: 22px;
  height: 100%;
  @include media-breakpoint-down(md) {
    gap: 10px;
  }
  h3 {
    text-transform: none;
    font-weight: 600;
  }
  img {
    max-width: 54px;
  }
}
.carousel-vault-enterprise,
.carousel-vault-home {

}
.carousel-vault-enterprise {

}
.carousel-vault-home {

}
.smart-who-we-connect {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  background-image: none;
  .feature-card {box-shadow: 4px 2px 4px 0 rgba(0,0,0,0.15);}
  @include media-breakpoint-up(lg) {
    background-size: contain;
    background-position: right center;
    background-image: url('#{$cdnPath}/img/smart/landing/smart-category-bg.jpg');
    .feature-card {box-shadow: none;}
  }
}