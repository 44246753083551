.container-banner {
  @media screen and (min-width: 1921px) {
    max-width: 1380px; // normal max for largest breakpoint in Bootstrap
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

.hero-banner {
  display: block;
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;

  > div {
    display: flex;
    justify-content: center;
    align-items: stretch;

    > * {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  .hero-banner-body {
    padding: 12px 12px 15px 12px;
    flex-direction: column;

    h2 {
      font-size: 16px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 12px;
      margin-top: 8px;
      font-family: $headings-font-family;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      padding: 15px;

      h2 {
        font-size: 20px;
        line-height: 26px;
      }

      p {
        font-size: 20px;
        font-weight: 300;
        line-height: 26px;
        margin-top: 0px;
        margin-left: 10px;
      }
    }
  }

  .hero-banner-cta {
    background-color: #B92626;
    clip-path: polygon(21px 0, 100% 0%, 100% 100%, 0 100%);
    padding: 0 15px 0 30px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: $headings-font-family;
    letter-spacing: 0.5px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 26px;
    }
  }
}