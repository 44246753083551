$content-column: 235px;
$two-column: $content-column * 2;
$four-column: $content-column * 4;
$three-column: $content-column * 3;
$text-column: 440px;
$photo-column: 235px;
$large-photo-column: $photo-column * 2;

/* Grids must have a combination of these column types that add up to the site wrapper (1380px) with 1fr on either side to center them */

.landing-grid {
    /* Mid-sized layout where text is on top and other content horizontal under it */
    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        .space-left {
            flex: 1;
            order: 1;
        }
        .text {
            order: 2;
            flex-grow: 0;
        }
        .space-right {
            flex: 1;
            order: 4;
        }
        .columns {
            max-width: none;
            flex-grow: 0;
            order: 3;
        }
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: 75px;
        flex-direction: row;
        align-items: stretch;
    }
}

@mixin white-background {
    background-color: #fff;
    @include media-breakpoint-up(lg) {
        background-color: transparent;
        background-image: url('#{$cdnPath}/img/landing-grid-white.png');
        background-repeat: repeat-x;
        border-bottom: 1px solid $border-color;
    }
}

@mixin white-background-right {
    
    @include media-breakpoint-up(lg) {
        background-color: transparent;
        background-image: url('#{$cdnPath}/img/landing-grid-right.png');
        border-right: none;
        border-top: none;
        border-bottom: 1px solid $border-color;
        background-repeat: no-repeat;
        background-position: top right;
        padding-right: 15px;
    }
    @include media-breakpoint-up(xxl) {
        border-left: none;
    }
}

@mixin white-background-left {
    
    @include media-breakpoint-up(lg) {
        background-color: transparent;
        background-image: url('#{$cdnPath}/img/landing-grid-left.png');
        border-left: none;
        border-top: none;
        border-bottom: 1px solid $border-color;
        background-repeat: no-repeat;
        background-position: top left;
        padding-left: 15px;
    }
    @include media-breakpoint-up(xxl) {
        border-left: none;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 15px;
    line-height: 1.2;
    max-width: $content-column;
    margin-left: auto;
    margin-right: auto;
    & > div {
        height: 170px;
        position: relative;
        margin-bottom: 15px;

        &.rectangle-image {
            height: 150px;
            margin: 15px 0;

            @include media-breakpoint-down(md) {
                height: 110px;
            }

            img {
                top: auto;
                bottom: 0;
                left: 0;
                transform: none;
            }
        }
    }

    &.two-wide {
        max-width: $content-column * 2;

        .body {
            max-width: $content-column;
            margin: 0 auto;
        }
    }

    p {
        line-height: 1.2;
        color: $dark;
    }
    img {
        max-height: 100%;
        vertical-align: middle;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @include media-breakpoint-up(lg) {
        .h6 {
            min-height:  38px;
        }
    }
    @include media-breakpoint-up(xxl) {
        padding: 25px 10px;
        margin: 0;
    }
}

.columns {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: $content-column;
    margin: auto;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-around;
        margin: 0;
    }
    @include media-breakpoint-up(xxl) {
        max-width: none;
        background-color: transparent;
    }
    @include media-breakpoint-down(xl) {
        background-color: #fff;
        border-top: 1px solid $border-color;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        max-width: $content-column + 100px;
        &:after {
            content: url("#{$cdnPath}/img/gray-corner.png");
            position: absolute;
            right: -1px;
            bottom: -3px;
            width: 14px;
            height: 36px;
        }
    }
}

.text {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    .text-container {
        padding-left: 15px;
        padding-right: 15px;
        @include media-breakpoint-up(xxl) {
            /* account for notch in neighboring white section */
            padding: 15px 30px;
        }
    }
    p {
        @include media-breakpoint-up(xxl) {
            font-size: 1.1rem;
        }
    }
    .btn {
        margin-bottom: 1em;
    }
}

.text {
    @include media-breakpoint-up(xxl) {
        width: $text-column;
    }
}

.text-container {
    max-width: 768px;
    margin: auto;
    @include media-breakpoint-up(xxl) {
        /* If the text gets really long, we don't want it making the columns really tall along with it */
        position: absolute;
    }
}

.image {
    display: none;
    @include media-breakpoint-up(xxl) {
        display: block;
        width: $photo-column;
        flex-grow: 0;
    }
    img {
        position: absolute;
        margin-top: -25px;
    }

    &.image-tablet {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.image-mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        flex-grow: 0;
    }
    img:not(.icon-play) {
        position: relative;
        width: 100%;
        margin: 0;
    }
}

/* Grid with white extending left, then four columns of possible content, then text on gray */
.landing-grid--white-locks-text {

    /* Full-sized layout */
    @include media-breakpoint-up(xxl) {
        .columns {
            justify-content: flex-end;
            @include white-background-right;
            width: $four-column;
            order: 2;
        }
        .space-left {
            @include white-background;
            order: 1;
        }
        .text {
            order: 3;
        }
        .space-right {
            order: 4;
        }
    }
}

/* Grid with a photo background on the left, then white extending right with text followed by three columns of possible content */
.landing-grid--photo-white-text-locks {
    @include media-breakpoint-up(xxl) {
        .image {
            order: 2;
        }
        .columns {
            justify-content: flex-end;
            @include white-background;
            order: 4;
            width: $three-column;
        }
        .space-left {
            order: 1;
        }
        .text {
            @include white-background-left;
            order: 3;
        }
        .space-right {
            @include white-background;
            order: 5;
        }
    }
}

/* Grid with a large photo background on the left, then white extending right with text followed by two columns of possible content */
.landing-grid--large-photo-white-text-locks {
    @include media-breakpoint-up(xxl) {
        .image {
            width: $large-photo-column;
            order: 2;
        }
        .columns {
            justify-content: flex-end;
            @include white-background;
            order: 3;
            width: $two-column;
        }
        .space-left {
            order: 1;
        }
        .text {
            @include white-background-left;
            order: 3;
        }
        .space-right {
            @include white-background;
            order: 5;
        }
    }
}

/* Grid with a large photo background on the left, then white extending right with text extending full width */
.landing-grid--large-photo-white-text-only {

  .text {
    max-width: 800px;
  }

    @include media-breakpoint-up(md) {

        .image-tablet {
            max-width: 735px;
            order: 2;
            margin: 0 auto;

            img:not(.icon-play) {
                position: relative;
                max-width: 100%;
                //margin: 0;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .image {
            width: 530px;
            order: 2;

            img:not(.icon-play) {
                max-width: 625px;
                margin-top: -25px;
            }

            &.img-xl {
                width: 600px;

                img {
                    max-width: 850px;
                }
            }
        }
        .text {
            @include white-background-left;
            order: 3;
            height: 330px;
            width: 50%;

            &.text-large {
                height: 400px;
                width: 800px;
            }
        }
        .text-container {
            max-width: none;
        }
        .space-right {
            @include white-background;
            order: 5;
        }
    }
}

.landing-grid--large-photo-white-text-right-only {

    @include media-breakpoint-up(md) {

        .image-tablet {
            max-width: 735px;
            order: 1;
            margin: 0 auto;

            img {
                position: relative;
                max-width: 100%;
                margin: 0;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .image {
            width: 530px;
            order: 3;

            img {
                max-width: 625px;
                margin-top: -25px;
                margin-left: -205px;
            }

            &.img-xl {
                width: 600px;

                img {
                    max-width: 850px;
                }
            }
        }
        .text {
            @include white-background-right;
            order: 2;
            height: 330px;
            width: 50%;

            &.text-large {
                height: 400px;
                width: 800px;
            }
        }
        .text-container {
            max-width: none;
        }
        .space-left {
            @include white-background;
            order: 1;
        }
    }
}

/* Grid with a large photo background on the left, then white extending right with text extending full width */
.landing-grid--large-photo-white-columns {
    .image {
        display: block!important;

        @include media-breakpoint-up(xxl) {
            width: 450px;
        }

        @include media-breakpoint-down(xl) {
            img {
                position: relative;
                max-width: 100%;
                margin: 0;
            }
        }
    }

    .text-container {
        @include media-breakpoint-down(xl) {
            max-width: 660px;
        }
    }

    .text {
        @include media-breakpoint-up(xl) {
            width: 58%;
        }
    }

    @include media-breakpoint-up(md) {

        .image-tablet {
            max-width: 735px;
            order: 2;
            margin: 0 auto;

            img {
                position: relative;
                max-width: 100%;
                margin: 0;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .image {
            width: $large-photo-column;
            order: 4;
            img {
                margin-left: -1 * $photo-column;
            }
        }
        .text {
            @include white-background-right;
            order: 2;
            height: 330px;
            width: 70%;
        }
        .text-container {
            max-width: none;
        }
        .space-left {
            @include white-background;
            order: 1;
        }
    }
}

/* Grid with white extending left with text followed by two columns of possible content, right a large photo to the right */
.landing-grid--text-locks-large-photo {
    @include media-breakpoint-up(xxl) {
        .image {
            width: $large-photo-column;
            order: 4;
            img {
                margin-left: -1 * $photo-column;
            }
        }
        .columns {
            justify-content: flex-end;
            @include white-background-right;
            width: $two-column;
            order: 3;
        }
        .space-left {
            @include white-background;
            order: 1;
        }
        .text {
            @include white-background;
            order: 2;
        }
        .space-right {
            order: 5;
        }
    }
}

/* Grid with text on the left, then white extending right with four columns of possible content */
.landing-grid-text-white-locks {
    /* Full-sized layout */
    @include media-breakpoint-up(xxl) {
        .columns {
            justify-content: flex-start;
            width: $four-column;
            @include white-background-left;
            order: 3;
        }
        .space-left {
            order: 1;
        }
        .text {
            order: 2;
        }
        .space-right {
            @include white-background;
            order: 4;
        }
    }
}
