.carousel {
  .carousel-controls-text {
    font-family: $headings-font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.56px;
    text-transform: uppercase;
    display: block;
    padding: 0 10px;

    span {
      display: block;
    }
  }

  .glide__track,
  .glide__slides {
    //overflow: visible;
  }

  .glide__slides {
    align-items: stretch;
  }

  .glide__slide {
    height: auto;
    > * {
      height: 100%;
    }
  }


  .glide__arrow {
    border: none;
    line-height: 1;
    background-color: transparent;
    transition: color 300ms ease;

    &:hover {
      cursor: pointer;
      color: #B92626;
    }
  }

  .glide__arrow:focus {
    border: none;
    outline: none;
  }

  .glide__bullets {
    display: flex;
    gap: 4px;
  }

  .glide__bullet {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    border: 2px solid #000;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }

  .glide__bullet--active {
    background-color: #000;
  }

  .glide__arrow--disabled {
    opacity: .2;
  }
}

.carousel-content {
  position: relative;
  &.glide {
    @include media-breakpoint-up(md) {
      //width: calc(100% - 60px);
      //margin: 0 auto;
    }
  }
  .glide__track {
    //margin: 0 auto;
    //width: calc(100% - 200px);
  }
  .glide__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .glide__slides {
    //margin: 0 auto;
    //width: calc(100% - 200px);
  }
  .glide__slide {
    .row {
      @include media-breakpoint-up(md) {
        margin: 0 75px;
        align-items: center;
      }
    }
  }
  .glide__arrow {
    //background-color: white;
    fill: #fff;
    width: 40px;
    height: 40px;

    &:hover {
      fill: $primary;
    }
    .glide__arrow-svg {
      width: 100%;
      height: 100-0%;
      min-width: 14px;
      vertical-align: middle !important;
    }
  }
  .glide__arrow--left {

    .glide__arrow-svg {
      transform: rotate(180deg);
    }
  }
  .glide__arrow--right {

  }
  .glide__bullets {
    justify-content: center;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .glide__bullet {
    width: 83px;
    height: 5px;
    background: #9E9E9E;
    border: none;
    border-radius: 0;
    transition: all 200ms ease-in-out;

    &:hover,
    &.glide__bullet--active {
      //height: 6px;
      background: #9A0700;
    }
  }
  .carousel-content-image {
    width: 100%;
    aspect-ratio: 418 / 273;
    display: block;
    object-fit: cover;
    object-position: center;
  }
}
//* {
//  outline: 1px solid tomato;
//}