
/* A centered block that has a chunk of intro text on the left and a chunk of content on the right, 
and may have a background that extends to the left or right */
.intro-content {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
    .intro-content__content {
        padding-top: 25px;
        padding-bottom: 25px;
        p {
            max-width: 300px;
            margin: auto;
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: stretch;
        margin: 0;
        .intro-content__space-left {
            flex: 1;
        }
        .intro-content__space-right {
            flex: 1;
        }
        .intro-content__content {
            max-width: 907px;
            width: 75%;
        }
        .intro-content__text {
            max-width: 907px;
            width: 25%;
            max-width: 473px;
            padding-right: 25px;
            padding-top: 0;
        }
    }
    @include media-breakpoint-up(lg) {
        .intro-content__text {
            padding-right: 50px;
        }
    }
}

.intro-content-list-grid {
    @include media-breakpoint-up(lg) {
        //Avoiding IE11
        @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto auto;
            grid-gap: 25px;
        }
    }

}

.intro-content--white-top-left {
    background-color: #fff;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    @include media-breakpoint-up(lg) {
        border-right: none;
        border-bottom: none;
    }
    @include media-breakpoint-up(xl) {
        @supports (display: grid) {
            background-image: url(#{$cdnPath}/img/options-chart-top.png);
            background-repeat: repeat-y;
            background-position: bottom left;
        }
    }
}

.intro-content--white-top-right {
    @include media-breakpoint-up(lg) {
        background-color: #fff;
        border-top: 1px solid $border-color;
    }
}

.intro-content--white-bottom-right {
    @include media-breakpoint-up(lg) {
        background-color: #fff;
        background-image: url(#{$cdnPath}/img/options-chart-bottom-bg.png);
        background-repeat: repeat-x;
        background-position: bottom left;
    }
}

.intro-content--white-left {
    background-color: #fff;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    @include media-breakpoint-up(lg) {
        border-right: none;
        border-bottom: none;
        border-top: none;
    }
    @include media-breakpoint-up(xl) {
        @supports (display: grid) {
            background-image: url(#{$cdnPath}/img/options-chart-bg.png);
            background-repeat: repeat-y;
            background-position: top left;
        }
    }
}

.intro-content--white-bottom-left {
    background-color: #fff;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    @include media-breakpoint-up(lg) {
        border-right: none;
        border-bottom: none;
        border-top: none;
    }
    @include media-breakpoint-up(xl) {
        @supports (display: grid) {
            background-image: url(#{$cdnPath}/img/options-chart-bottom.png);
            background-repeat: repeat-y;
            background-position: bottom left;
            border-left: none;
        }
    }
}

.intro-content--white {
    @include media-breakpoint-up(lg) {
        background-color: #fff;
    }
}

.intro-content__heading {
    font-size: 1.1rem;
    font-weight: 800;
    line-height: 1.5;
    text-align: center;
    letter-spacing: normal;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.intro-content__subheading {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2.3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-content__label {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
}