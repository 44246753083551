.landing-card {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    max-width: 370px;
    .landing-card__image {
        height: 200px;
        width: 100%; 
        background-size: cover;
        background-position: center top;
        position: relative;
        flex-grow: 1;
        flex-shrink: 0;
        &:after {
            content: url('#{$cdnPath}/img/gray-corner-plain-right.png');
            width: 12px;
            height: 29px;
            position: absolute;
            bottom: -1px;
            right: -1px;
        }
    }
    .landing-card__content {
        padding-left: 15px;
        padding-right: 15px;
    }
    @include media-breakpoint-up(lg) {
        flex-basis: 50%;
        margin-right: 3rem;
    }
    @include media-breakpoint-up(xl) {
        flex-direction: row;
        max-width: none;
        margin-right: 0;
        .landing-card__image {
            width: 248px;
            height: 398px;
        }
        .landing-card__content {
            padding-left: 3rem;
            padding-right: 5rem;
            padding-top: 1rem;
        }
        &:last-child {
            .landing-card__image {
                &:after {
                    content: url('#{$cdnPath}/img/gray-corner-plain-left.png');
                    width: 12px;
                    height: 29px;
                    position: absolute;
                    bottom: auto;
                    right: auto;
                    top: -1px;
                    left: -1px;
                }
            }
        }
    }
}

.landing-card-horizontal {
    display: flex;
    flex-direction: row;
    flex-basis: 50%;

    .landing-card__image {
        height: 200px;
        width: 150px;
        background-size: cover;
        background-position: center top;
        position: relative;

        &:after {
            content: url('#{$cdnPath}/img/gray-corner-plain-right.png');
            width: 12px;
            height: 29px;
            position: absolute;
            bottom: -1px;
            right: -1px;
        }
    }

    .landing-card__content {
        width: calc(100% - 200px);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
    }
}