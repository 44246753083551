.product-card:hover .card {
    border-color: #fff;
    box-shadow: -6px 10px 18px 0 rgba(0,0,0,.2);

    a {
        text-decoration: none;
    }
}

.product-card-compare {
    bottom: 16px;
    right: 32px;
}

.product-card-compare__label {
    margin-bottom: 0;
    margin-left: 5px;
    //height: 27px;
}

.product-card-compare__button {
    padding: 0;
    font-weight: bold;
    margin-left: 5px;
}

.product-card {
    .product-card-compare__button {
        display: none;
    }
    .product-card-compare__label {
        display: inline-block;
    }
    .cta-text {
        color: $primary;
        text-decoration: underline;
    }
    a {
        color: inherit;
    }
    [class*="btn-outline-"] {
        font-size: .8rem;
        line-height: 1.6;
        padding: 0.4rem 15px;

        &:hover,
        &:active {
            background-color: $primary !important;
            border-color: $primary !important;
        }

    }
}

.product-card .is-comparing .product-card-compare {
    .product-card-compare__button {
        display: inline-block;
    }
    .product-card-compare__label {
        display: none;
    }
}

.product-card-price {
    font-size: 1.2rem;
}

.as-sentry-safe {
    .card-flag {
        background-color: transparent;
    }
}