.compare {
    border-collapse: separate;
    border-spacing: 15px 0;
    table-layout: fixed;
    font-size: .9em;
    //On large screens, evenly distribute columns up to 100%
    @include media-breakpoint-up(xl) {
        width: 100%;
    }
    //this is just like Bootstrap's table-striped, but letting us have transparent th in rows
    tbody tr:nth-of-type(even) td {
        background-color: $table-accent-bg;
    }
    p, li {
        font-size: 1em;
        line-height: 1.4rem;
    }
    li {
        margin-bottom: 8px;
    }
    td {
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        padding: 15px;
        vertical-align: top;
    }
    thead th {
        //width of products
        min-width: 300px;
        @include media-breakpoint-up(xl) {
            min-width: auto;
        }
    }
    //width of labels
    thead th:first-child {
        width: 140px;
        min-width: 140px;
    }
    th {
        vertical-align: top;
    }

    @media only screen and (min-device-pixel-ratio: 2) {
    p, li {
            font-size: .9rem;
        }
    }

    img.compare-img {
        width: 100%;
        max-width: 150px;
    }
}

.compare-container {
    overflow: auto;
    padding-bottom: 15px;
}

.compare-header {
    padding: 15px;
    img {
        max-height: 295px;
        margin: auto;
        display: block;
    }
}

.product-compare {
    font-size: 80% !important;
}