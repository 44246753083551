.insights {
  .component {
    padding-top: 20px;

    ul,
    ol {
      clear: both;
      margin-top: 1rem;
    }
  }


  //.ls-sm {
  //  letter-spacing: $letter-spacing-sm;
  //}
  //
  //.ls-md {
  //  letter-spacing: $letter-spacing-md;
  //}
  //
  //.ls-lg {
  //  letter-spacing: $letter-spacing-lg;
  //}
  //
  //.ff-base {
  //  font-family: $font-family-base;
  //}

  //.fs-xs {
  //  font-size: $font-size-xs;
  //}

  .fs-large {
    font-size: 1.15rem;
  }

  .fw-semibold {
    font-weight: 700;
  }

  img:not(.rounded-circle) {
    max-width: 100%;
  }

  .text-decoration-none {
    text-decoration: none;
  }

  .text-decoration-hover {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn-video-wrap, .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .btn-video-wrap {
    z-index: 3;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .react-player {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
  }

  .video-thumb {
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 100%;
  }

  .icon-play {
    z-index: 2;
    width: 75px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  //.icon-external {
  //  display: inline-block;
  //  vertical-align: middle;
  //  width: 10px;
  //  height: 14px;
  //  background: transparent url("../../public/external-link.png") no-repeat top left;
  //  background-size: 100%;
  //
  //}

  a:hover {
    color: #7a1919;
  }

  .ankle__image {
    max-width: 110px;
  }

  // .footer_items {
  //   @include media-breakpoint-down(sm) {
  //     text-align: center;
  //   }
  // }

  .image__with_text__img_left {
    float: left;
    padding: .5rem 2rem 1rem 0;
    margin: 0;
    width: 50%;

    img {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  .image__with_text__img_right {
    float: right;
    padding: .5rem 0 1rem 2rem;
    margin: 0;
    width: 50%;

    img {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  .header__image {

    .header__large {
      position: relative;

      @include media-breakpoint-down(sm) {
        img {
          width: 90%;
        }
      }

      &:after {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        width: 1px;
        height: 32px;
        content: '';
        background-color: $dark;

        @include media-breakpoint-down(sm) {
          width: 75%;
          height: 1px;
          top: 100%;
          left: 0;
          margin: auto;
        }
      }
    }
  }

  .social {

    button {
      padding-left: 8px !important;
    }
  }

  .no__underline {
    text-decoration: none;
  }

  .article-card {
    width: 100%;

    &:hover {
      box-shadow: -6px 10px 18px 0 rgba(0, 0, 0, .2);
    }
  }

  .card-image {
    //max-height: 210px;
    //overflow: hidden;

    img {
      width: 100%;
      aspect-ratio: 181/119;
      object-fit: cover;

      @include media-breakpoint-down(lg) {
        aspect-ratio:  243/123;
      }
    }

    //@include media-breakpoint-up(lg) {
    //  max-height: 245px;
    //}
  }

  .card-featured img {
    aspect-ratio: 243/68;

    @include media-breakpoint-down(lg) {
      aspect-ratio:  243/123;
    }
  }

  .form-check.active {
    .form-check-label {
      font-weight: bold;
    }
  }

  .link-dark {
    color: #010101 !important;
    div {
      .h5 {
        text-transform: none !important;
        font-size: 1.125rem !important;
        letter-spacing: normal;
      }
    }
  }
  .link-dark:hover {
    color: #b92626 !important;
  }

  //.card-image {
  //  max-height: 210px;
  //  overflow: hidden;
  //
  //  img {
  //    width: 100%;
  //  }
  //
  //  @include media-breakpoint-up(lg) {
  //    max-height: 245px;
  //  }
  //}
  //
  //.card-featured img {
  //
  //  @include media-breakpoint-down(lg) {
  //    transform: translateX(-33%);
  //    width: auto;
  //    max-width: none;
  //  }
  //}

  /* Start componentStatistic specific */
  .statvalue {
    color: $primary;
    font-family: $headings-font-family;
    font-weight: 800;
  }
  .stattext {}

  /* Start componentQuote specific */
  .quote {
    color: black;
    quotes: '\201c''\201d';
  }

  .quote:before {
    content: open-quote;
  }

  .quote:after {
    content: close-quote;
  }

  .quoteSource {
    font-weight: bolder;
    color: $primary;
  }

  .quoteSource:before {
    content: "-";
  }

  /* Start Rich Text */
  .page {
    margin-left: auto;
    margin-right: auto;
  }

  .page__content {
    /* margin-bottom: 4rem; */
  }

  .page__linkedHeaderContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .page__linkedHeaderContainer h2 {
    margin-bottom: 0;
  }

  .page__headerLink {
    color: var(--color-primary);
    margin-left: 0.5rem;
  }

  .page__hr {
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-color: transparent;
    border-bottom: 0.25rem solid var(--color-primary);
  }

  .page__ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 3rem;
    margin-block-end: 3rem;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 4rem;
  }

  .page__ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 3rem;
    margin-block-end: 3rem;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 4rem;
  }

  .page__li {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    font-weight: var(--font-weight-light);
    font-family: var(--font-family-main);
    color: var(--color-foreground);
  }

  .page__imgContainer {
    width: 100%;
    height: auto;
    margin: 1rem 0 2rem 0;
  }

  .contentful-wysiwyg {
    h2 {
      font-size: 1.25rem !important;
      line-height: 1.2 !important;
      margin-top: 0 !important;
      margin-bottom: .5rem !important;
      text-transform: none !important;

    }
    a {
      text-decoration: none;
      color: $primary !important;

      &:hover {
        color: #7a1919 !important;
        text-decoration: underline;
      }
    }
    :last-child {
      margin-bottom: 0 !important;
    }
  }

  h1 {
    @extend .h1;
    text-transform: none !important;
    letter-spacing: normal;

    @include media-breakpoint-down(lg) {
      font-size: calc(1.3rem + .6vw);
    }
  }

  h2 {
    text-transform: none !important;
    letter-spacing: normal;
  }

  h3, h4, h5, h6 {
    letter-spacing: normal;
  }

  .form-check {
    display: flex;
    align-items: center;
    label {
      &:hover {
        cursor: pointer;
      }
    }
    input {
      margin-top: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #b92626;
    border-color: #b92626
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
  }

  .form-check-input[type=checkbox]:indeterminate {
    background-color: #b92626;
    border-color: #b92626;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
  }

  .glide__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .glide__arrow {
    background-color: #b92626;
    fill: #fff;
    width: 40px;
    height: 40px;

    .glide__arrow-svg {
      width: 50%;
      height: 50%;
      min-width: 14px;
      vertical-align: middle !important;
    }
  }
  .glide__arrow--left {
    transform: translateX(-15px);
    .glide__arrow-svg {
      transform: rotate(180deg);
    }
  }
  .glide__arrow--right {
    transform: translateX(15px);
  }
  .glide__bullets {
    gap: 10px;
  }
  .glide__bullet {
    box-shadow: none;
    &:focus {
      outline: none;
    }
    &--active {
      background-color: #b92626;
      border-color: #b92626;
    }
  }

  .back-to-top-insights {
    position: fixed;
    bottom: 40px;
    right: 30px;
    background-image: url(#{$cdnPath}/img/button-back-to-top.png);
    background-color: transparent;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 54px;
    height: 50px;
    z-index: 99;
    border: none;
    outline: none;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    &:hover {
      cursor: pointer;
    }
    &.active {
      opacity: 1;
      &:hover {
        opacity: .8;
      }
    }
  }
}
