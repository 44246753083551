.feature-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @include media-breakpoint-up(lg) {
    gap: 1.875rem;
  }

  &-horizontal {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.feature-card {
  background: #fff;
  display: flex;
}
.feature-card-body {

}
.feature-card-img {
  flex-shrink: 0;
  max-height: 187px;
}
.feature-card-vertical {
  flex-direction: column;
  .feature-card-img {
    max-height: none;

    img {
      object-fit: cover;
      width: 100%;
      aspect-ratio: 380 / 246;
      max-height: none;
      object-position: center;
    }
  }
  .feature-card-body {
    padding: 24px 16px 32px;
    border: 1px solid #979797;
    flex-grow: 1;
  }
}
.feature-card-1x1 {
  img {
    aspect-ratio: 1 / 1 !important;
  }
}
.feature-card-product {
  .feature-card-img {
    border: 1px solid #979797;
    border-width: 1px 1px 0;
  }
  .feature-card-body {
    background-color: #F8F9FA;
    color: $body-color !important;
  }
  img {
    object-fit: contain !important;
    padding: 20px;
  }
}