.accordion__toggle {
    display: block;
    position: relative;
    width: 100%;
    min-height: 44px;
    border-radius: 0;
    background-color: $dark;
    color: #fff;
    border: 1px solid $dark;
    text-transform: uppercase;
    padding: 5px 15px;
    text-align: left;
    font-size: 1rem;
    letter-spacing: 1.6px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-top: 10px;
    line-height: 1rem;
    cursor: pointer;
    span {
        width: calc(100% - 50px);
        display: inline-block;
    }
    &:after {
        content: " ";
        background-image: url('#{$cdnPath}/img/down-arrow.svg');
        background-position: 5px center;
        background-repeat: no-repeat;
        width: 46px;
        color: #fff;
        position: absolute;
        height: 100%;
        padding-left: 5px;
        padding-right: 10px;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $dark;
        border-left: 5px solid $dark;
    }
    &[aria-expanded=true] {
        background-color: #fff;
        color: $dark;
        &:after {
            /* This entire block is upside down */
            padding-left: 5px;
            padding-right: 10px;
            transform: rotate(180deg);
            border-right: 5px solid $featured;
        }
    }
    &[aria-expanded=true]:focus {
        background-color: #fff;
        color: $dark;
        outline: none;
    }
    &[aria-expanded=false]:focus:not(:focus-visible) {
        outline: none;
    }
    &[aria-expanded=false]:focus-visible,&[aria-expanded=true]:focus-visible {
        outline: none;
        span {
            outline: 2px solid $featured;
            outline-offset: 4px;
        }
    }
}

//This one forces the label to wrap
.accordion__toggle--large {
    min-height: 50px;
    span {
        width: 150px;
    }
}

//This one stays black even on open
.accordion-toggle--dark {
    &[aria-expanded=true], &[aria-expanded=true]:focus {
        background-color: $dark;
        color: #fff;
    }
    &[aria-expanded=false]:hover, &[aria-expanded=false]:focus {
        background-color: $dark;
        color: $featured;
    }
}

.accordion {
    &.accordion--light {
        overflow-anchor: none;
        .accordion__toggle {
            background-color: $white !important;
            color: $dark;
            font-size: .9rem;
            border: 1px solid rgba(0,0,0,0.125);
            border-width: 1px 1px 0px 1px;
            white-space: normal;

            &:after {
                width: 53px;
                padding-left: 5px;
                padding-right: 5px;
                border-right: 5px solid #FFC300;
                border-left: 5px solid transparent;
                background-color: $white;
                background-image: url('#{$cdnPath}/img/icon-arrow.svg');
                background-position: center;
                transform: rotate(180deg);
            }

            &:active,
            &:focus {
                box-shadow: none !important;
            }

            &:hover,
            &:active {
                color: $primary;
            }
        }
        .accordion__toggle[aria-expanded=true]:after {
            border-right: 5px solid transparent;
            border-left: 5px solid #FFC300;
            transform: rotate(0deg);
        }
        .card {
            border: none;
        }
        .card-header {
            //border: 1px solid rgba(0,0,0,0.125);
        }
        .card-body {
            background-color: #F8F8F8;
            border: 1px solid rgba(0,0,0,0.125);
            border-width: 0px 1px 1px 1px;
        }
    }
}

//[data-toggle="collapse"] {
//    font-family: 'Montserrat', Arial, sans-serif;
//    font-size: 12px;
//    font-style: normal;
//    font-weight: 700;
//    line-height: normal;
//    letter-spacing: 1.5px;
//    text-transform: uppercase;
//    border: 2px solid #363636;
//    display: flex;
//    padding: 14px 16px;
//    justify-content: space-between;
//    align-items: center;
//    width: 100%;
//
//    &:focus {
//        outline: none;
//    }
//}
//
//[data-toggle="collapse"] ~ .collapse,
//[data-toggle="collapse"] ~ .collapsing {
//    border: 2px solid #363636;
//    padding: 20px;
//    margin-top: -2px;
//}