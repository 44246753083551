.next-gen-lock-boxes {
  .landing-hero__background {
    @include media-breakpoint-up(lg) {
      min-height: 620px;
    }
  }

  .model-listing {
    background: linear-gradient(180deg, rgba(248, 248, 248, 1) 0%, rgba(248, 248, 248, 1) 15%,
            rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 100%);

    @include media-breakpoint-up(lg) {
      background: linear-gradient(180deg, rgba(248, 248, 248, 1) 0%, rgba(248, 248, 248, 1) 50%,
              rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    }

    .row {
      //margin-top: -80px;

      @include media-breakpoint-up(md) {
        //margin-top: 0;
      }
    }

    img {
      max-width: 100px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        max-width: 150px;
      }
    }

    picture {
      text-align: center;
      margin-top: 20px;

      @include media-breakpoint-up(lg) {
        margin-top: 0px;
      }
    }
  }

  .image-collage {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 5%,
            rgba(54, 54, 54, 1) 5%, rgba(54, 54, 54, 1) 100%);

    @include media-breakpoint-up(md) {
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 14%,
              rgba(54, 54, 54, 1) 14%, rgba(54, 54, 54, 1) 100%);
    }
  }
}