.loto-assessment {

  .form-control:focus {
    border-color: $secondaryAlt;
  }
}

.icon-no-mobile {
  @include media-breakpoint-down(sm) {
    background: none!important;
  }
}

.assessment-label {
  padding-left: 33px;
  font-size: 12px !important;
  cursor: pointer;
  letter-spacing: .063rem!important;
}

.form-check-label {
  font-size: 14px !important;
  color: #757575;
  font-weight: normal;
}

.assessment-radio {
  position: absolute !important;
  top: 1px;
  left: 0px;
  accent-color: #007A96;
  width: 18px;
  height: 19px;
}

.assessment-landing-header .featured-text {
  font-size: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.response {
  font-weight: 100 !important;
}

.question {
  padding-top: 10px;
  padding-left: 0px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: none !important;
}

.assessment-title {
  font-size: 14px !important;
  font-weight: bold !important;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: .15em;
}

.field-error {
  color: #9A0700;
  font-size: 10px;
}

.result-header {
  font-size: 16px;
  color: #00708A;
  padding-top: 20px;
  padding-bottom: 20px;
}

.result-title {
  font-size: 48px;
}

.result-section-title {
  font-size: 1.5rem;
  font-weight: 100 !important;
  text-transform: uppercase;
}

.q-cols {
  height: 100%;
}

.assessment-header {

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 325px;
    background: url(#{$cdnPath}/img/loto/assessment/loto-assessment-header-desktop.png);
    background-size: auto;
    background-position: top left;
    background-repeat: no-repeat;
  }

  .header-image {
    overflow: hidden;
    height: 90px;

    img {
      position: relative;
      top: 0;
      width: 200%;
      left: -100%;
    }

    @include media-breakpoint-up(sm) {
      img {
        width: 100%;
        left: 0;
      }
    }
  }
}

.assessment-header-title {
  font-size: 1.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.25rem;
  }
}

.assessment-progress-line {
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 1px;
    margin: 10px 0 0 38px;
    height: 100%;
    background: #ccc;
  }
}

.assessment-step-indicator {
  position: absolute;
  left: 12px;
  top: 0;
  width: 88px;
  height: 88px;
  background: transparent url(#{$cdnPath}/img/loto/assessment/safety-icon-sprite.png) no-repeat 0 0;
  background-size: 265px 1056px;
}

.assessment-form-group {
  position: relative;
  padding-left: 33%;

  &.comments {
    padding-left: 0;
  }

  @include media-breakpoint-up(md) {
    padding-left: 18%;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 11.333%;
  }

  .assessment-title, .assessment-label, .assessment-radio, .assessment-textarea {
    opacity: .4;
    cursor: not-allowed;
  }

  &.loto-step-1 .assessment-step-indicator {
    background-position: -176px 0;
  }
  &.loto-step-2 .assessment-step-indicator {
    background-position: -176px -88px;
  }
  &.loto-step-3 .assessment-step-indicator {
    background-position: -176px -176px;
  }
  &.loto-step-4 .assessment-step-indicator {
    background-position: -176px -264px;
  }
  &.loto-step-5 .assessment-step-indicator {
    background-position: -176px -352px;
  }
  &.loto-step-6 .assessment-step-indicator {
    background-position: -176px -440px;
  }
  &.loto-step-7 .assessment-step-indicator {
    background-position: -176px -528px;
  }

  &.elec-step-1 .assessment-step-indicator {
    background-position: -176px 0;
  }
  &.elec-step-2 .assessment-step-indicator {
    background-position: -176px -88px;
  }
  &.elec-step-3 .assessment-step-indicator {
    background-position: -176px -616px;
  }
  &.elec-step-4 .assessment-step-indicator {
    background-position: -176px -704px;
  }
  &.elec-step-5 .assessment-step-indicator {
    background-position: -176px -792px;
  }
  &.elec-step-6 .assessment-step-indicator {
    background-position: -176px -880px;
  }
  &.elec-step-7 .assessment-step-indicator {
    background-position: -176px -968px;
  }

  &.step-active {
    .assessment-title, .assessment-label, .assessment-radio {
      opacity: 1;
      cursor: pointer;
    }

    .assessment-textarea {
      opacity: 1;
      cursor: default;
    }

    &.loto-step-1 .assessment-step-indicator {
      background-position: -88px 0;
    }
    &.loto-step-2 .assessment-step-indicator {
      background-position: -88px -88px;
    }
    &.loto-step-3 .assessment-step-indicator {
      background-position: -88px -176px;
    }
    &.loto-step-4 .assessment-step-indicator {
      background-position: -88px -264px;
    }
    &.loto-step-5 .assessment-step-indicator {
      background-position: -88px -352px;
    }
    &.loto-step-6 .assessment-step-indicator {
      background-position: -88px -440px;
    }
    &.loto-step-7 .assessment-step-indicator {
      background-position: -88px -528px;
    }


    &.elec-step-1 .assessment-step-indicator {
      background-position: -88px 0;
    }
    &.elec-step-2 .assessment-step-indicator {
      background-position: -88px -88px;
    }
    &.elec-step-3 .assessment-step-indicator {
      background-position: -88px -616px;
    }
    &.elec-step-4 .assessment-step-indicator {
      background-position: -88px -704px;
    }
    &.elec-step-5 .assessment-step-indicator {
      background-position: -88px -792px;
    }
    &.elec-step-6 .assessment-step-indicator {
      background-position: -88px -880px;
    }
    &.elec-step-7 .assessment-step-indicator {
      background-position: -88px -968px;
    }
  }

  &.step-complete {
    .assessment-title, .assessment-label, .assessment-radio, .assessment-textarea {
      opacity: 1;
      cursor: pointer;
    }

    &.loto-step-1 .assessment-step-indicator {
      background-position: 0 0;
    }
    &.loto-step-2 .assessment-step-indicator {
      background-position: 0 -88px;
    }
    &.loto-step-3 .assessment-step-indicator {
      background-position: 0 -176px;
    }
    &.loto-step-4 .assessment-step-indicator {
      background-position: 0 -264px;
    }
    &.loto-step-5 .assessment-step-indicator {
      background-position: 0 -352px;
    }
    &.loto-step-6 .assessment-step-indicator {
      background-position: 0 -440px;
    }
    &.loto-step-7 .assessment-step-indicator {
      background-position: 0 -528px;
    }


    &.elec-step-1 .assessment-step-indicator {
      background-position: 0 0;
    }
    &.elec-step-2 .assessment-step-indicator {
      background-position: 0 -88px;
    }
    &.elec-step-3 .assessment-step-indicator {
      background-position: 0 -616px;
    }
    &.elec-step-4 .assessment-step-indicator {
      background-position: 0 -704px;
    }
    &.elec-step-5 .assessment-step-indicator {
      background-position: 0 -792px;
    }
    &.elec-step-6 .assessment-step-indicator {
      background-position: 0 -880px;
    }
    &.elec-step-7 .assessment-step-indicator {
      background-position: 0 -968px;
    }
  }
}


.assessment-divider {
  padding-top: 60px;
  padding-bottom: 60px;

  @media only screen and (max-width: 900px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.divider-sm { padding-top: 70px; padding-bottom: 0px;}

.form-center {
  @media only screen and (min-width: 767px) {
    padding-left: 150px !important;
  }
}

.hidden-xs {
  @media only screen and (max-width: 900px) {
    display: none;
  }
}

#assessment-opt-in {
  background-color: white;
  padding-bottom: 100px;
}

#auto-opt-in-text {
  font-size: 10pt;
}


.electrical-header {

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 325px;
    background: url(#{$cdnPath}/img/loto/assessment/elect-assessment-header-desktop.png);
    background-size: auto;
    background-position: top left;
    background-repeat: no-repeat;
  }

  .header-image {
    overflow: hidden;
    height: 90px;

    img {
      position: relative;
      top: 0;
      width: 200%;
      left: -100%;
    }

    @include media-breakpoint-up(sm) {
      img {
        width: 100%;
        left: 0;
      }
    }
  }
}

.electrical-header-title {
  font-size: 1.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.25rem;
  }
}
