.accent-separator {
    display: inline-block;
    background-color: $light;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    letter-spacing: 2.5px;
    margin-top: 45px;
    padding: 0 40px;

    &:after {
        content: " ";
        background-image: url('#{$cdnPath}/img/down-arrow-small-black.svg');
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        margin: auto;
        width: 30px;
        height: 15px;
    }
}

.accent-separator-container {

    .accent-separator {
        margin-top: 0;
    }

    @include media-breakpoint-up(sm) {
        background-image: url(#{$cdnPath}/img/drawer-bg-large.png);
        background-position: right center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 0;

        .accent-separator {
            margin-top: 45px;
        }
    }
}