.lightbox-video,
.lightbox-video-brightcove {
  @include media-breakpoint-up(lg) {

    .overlay-effect::after {
      opacity: 0;
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transition: all .2s ease;
      background-color: rgba(0, 0, 0, .4);
    }

    &:hover {

      .overlay-effect::after {
        opacity: 1;
      }
    }

    .icon-play {
      z-index: 2;
    }
  }
}