.landing-hero--community {
  @include media-breakpoint-down(md) {
    margin-top: -42px;
  }

  @include media-breakpoint-down(lg) {
    &:after {
      content: url('#{$cdnPath}/img/landing-hero-angle.svg');
      position: absolute;
      right: 0;
      bottom: 0;
      width: 30px;
      height: 194px;
      overflow: hidden;
    }
  }

  .landing-hero__background {
    background-size: auto;

    @include media-breakpoint-down(md) {
      background-size: cover;
      min-height: 350px;
    }
  }

  .breadcrumb {
    position: absolute;
    left: 1.5rem;
    top: 0;

    @include media-breakpoint-down(sm) {
      left: 0;
    }
  }

  .heading-community {
    text-shadow: 4px 6px 9px rgba(#000,0.7);
  }
}

.landing-hero--community-form {
    @include media-breakpoint-down(sm) {
      &:after {
        display: none;
      }
    }

    .landing-hero__background {
      background-size: cover;

      @include media-breakpoint-down(sm) {
        min-height: 290px;
        background-position: 66% 0 !important;
      }
      .breadcrumb {

        @include media-breakpoint-up(lg) {
          left: 9%;
          padding-left: 1rem;
        }
      }
    }
}

.community-lion-logo {
  width: 100%;
  max-width: 210px;

  @include media-breakpoint-up(lg) {
    max-width: 179px;
  }
}

.champion-how-it-works {

  .list-body {
    line-height: 1.4;
  }

  .raised-box {
    border: 1px solid $border-color;
    box-shadow: 6px 4px 6px 0 rgba(0,0,0,.15);
    background-color: #fff;
  }

  .raised-box-section_cc {
    flex: 1 1 auto;
    height: 107px;

    @include media-breakpoint-up(lg) {
      height: 181px;
    }
  }

  .raised-box-middle_cc {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    box-shadow: 6px 4px 6px 0 rgba(0, 0, 0, .15);
    position: relative;
    z-index: 2;

    &:after {
      content: url(#{$cdnPath}/img/raised-box-angle.png);
      height: 120px;
      position: absolute;
      top: -1px;
      right: -49px;
      z-index: -1;
    }

    @include media-breakpoint-up(lg) {

      &:after {
        content: url(#{$cdnPath}/img/raised-box-angle-cc.png);
        height: 180px;
        right: -75px;
      }
    }

    .raised-box-middle-offset {
      @include media-breakpoint-down(md) {
        margin-left: 19%;
      }

      @include media-breakpoint-down(xs) {
        margin-left: 0;
      }
    }
  }
}

.champion-card {
  .card-title {
    @include media-breakpoint-down(md) {
      font-size: 1.35rem;
    }
  }

  .card-text {
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
}

.champion-grid {

  @include media-breakpoint-down(xl) {
    text-align: center;
  }

  &.landing-grid--large-photo-white-text-only {
    .image {
      display: block!important;

      @include media-breakpoint-up(xxl) {
        width: 450px;
      }

      @include media-breakpoint-down(xl) {
        img {
          position: relative;
          max-width: 100%;
          margin: 0;
        }
      }
    }

    .text-container {
      @include media-breakpoint-down(xl) {
        max-width: 660px;
      }
    }

    .text {
      @include media-breakpoint-up(xl) {
        width: 58%;
      }
    }
  }
}

.champion-testimonial {
  .testimonial-name {
    text-transform: none!important;
    margin: .5rem 0;
    letter-spacing: normal;

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .testimonial-location {
    text-transform: none!important;
    letter-spacing: normal;

    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
  }

  .collapse.show {
    display: inline!important;
  }
}

.community-winner {

  .video-js {
    max-width: 100%;
    width: 315px;
    height: 175px;

    @include media-breakpoint-up(md) {
      width: 750px;
      height: 425px;
    }

    .vjs-dock-text {
      display: none;
    }

    &:hover .vjs-big-play-button {
      background-color: rgba(0,0,0,.75)
    }

    .vjs-big-play-button {
      top: 38%;
      left: 43%;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border-style: solid;
      border-width: 3px;
      border-color: #FFFFFF;
      margin: auto;
      line-height: 1.4;

      &:hover {
        background-color: rgba(0,0,0,.75)
      }

      &:before {
        margin: 20px auto;
        opacity: 1;
        font-size: 32px;
      }

      @include media-breakpoint-down(sm) {
        top: 25%;
        left: 35%;
      }
    }
  }



  .angle-up {
    .landing-hero__background {
      @media only screen and (max-width: 1024px) {
        background: #f8f8f8 !important;
      }

      @media only screen and (min-width: 1025px) {
        max-height: 600px;
        display: block;
      }
    }
  }
  .offset-winner-body {
    max-width: 100%;
    color: $body-color;

    @media only screen and (min-width: 1025px) {
      margin-left: 63%;
      max-width: 36%;
      color: #fff;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    @media only screen and (min-width: 1281px) {
      margin-left: 55%;
      max-width: 42%;
    }
  }

  .landing-grid {
    @include media-breakpoint-down(sm) {
      .text {
        padding: 0;
      }
      .image-tablet {
        display: block;
        width: 100%;
        flex-grow: 0;
        margin-bottom: 1rem;

        img {
          position: relative;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.youtube-video {
  .text-container {

    @include media-breakpoint-down(xl)  {
      max-width: 50%;
    }

    @include media-breakpoint-down(md)  {
      max-width: 90%;
    }

    @include media-breakpoint-down(sm)  {
      max-width: none;
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }

  @include media-breakpoint-down(md) {
    border: 1px solid #ababab;
    margin: 60px 15px 15px 15px;
    background: #fff;


    .lightbox-video.image {
      text-align: center;
    }

    .image-mobile {

      padding: 0 15px;
      margin-top: -30px;
    }

    .space-left { display: none; }
  }
}