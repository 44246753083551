.photo-link-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-align: center;
    position: relative;
    width: 300px;
    height: 150px;
    background-color: #000;
    background-size: cover;
    background-position: center center;
    margin: 5px;
    color: #fff;
    font-family:'Montserrat', sans-serif;
    font-weight: bold;
    font-size: .9rem;
    line-height: 1.1;
    letter-spacing: 2px;
    text-transform: uppercase;
    &:before {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.3);
    }
    @include media-breakpoint-up(lg) {
        max-width: 270px;
        padding: 15px 20px;
    }
    span {
        position: relative;
        z-index: 1;
    }
    &:hover {
        text-decoration: none;
        color: #fff;
        &:before {
            background-color: rgba(0,0,0,.7);
        }
    }
}

li:last-child .photo-link-block {
    &:after {
        content: url('#{$cdnPath}/img/gray-corner-plain-right.png');
        width: 12px;
        height: 29px;
        position: absolute;
        bottom: -1px;
        right: -1px;
    }
}

.photo-block {
  position: relative;

  &:after {
    content: url('#{$cdnPath}/img/landing-hero-angle.svg');
    position: absolute;
    right: -18px;
    bottom: 0;
    width: 95px;
    height: 167px;
  }
  .photo-wrapper {
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.2);
  }
}
