/* The point where nav items in the landing-hero are no longer two lines */
$nav-item-breakpoint: 1355px;

.landing-hero {
    background-color: #000;
    color: #fff;
    position: relative;
    @include media-breakpoint-up(lg) {
        &:after {
            content: url('#{$cdnPath}/img/landing-hero-angle.svg');
            position: absolute;
            right: 0;
            bottom: 0;
            width: 74px;
            height: 194px;
        }
    }

    &.angle-up {
        @include media-breakpoint-up(lg) {
            &:after {
                content: url('#{$cdnPath}/img/landing-hero-angle.svg');
                position: absolute;
                right: 0;
                top: 0;
                transform: scaleY(-1);
                bottom: 100%;
                width: 74px;
                height: 194px;
            }
        }
    }
    &.angle-none {
        &:after {
            display: none;
        }
    }
    .breadcrumb li a {
        color: #fff;
        text-decoration: underline;
    }
    .breadcrumb li {
        color: #fff;
    }

    &.no-angle {
        @include media-breakpoint-up(lg) {
            &:after {
                display: none;
            }
        }

        .landing-hero__background {
            @include media-breakpoint-down(md) {
                background-position: bottom left!important;
            }
        }
    }
    &.no-image-mobile .landing-hero__background {
        @include media-breakpoint-down(md) {
            background-image: none !important;
        }
    }
}

.landing-hero__background {
    background-size: cover;
    background-position: right top;
    @include media-breakpoint-up(lg) {
        min-height: 480px;
    }
}

.landing-hero__gradient {
    width: 100%;
    height: 100%;
    background:linear-gradient(to right, rgba(0,0,0,.7) 0%, rgba(0,0,0,.3) 100%);
    @include media-breakpoint-up(lg) {
        background: linear-gradient(to right, rgba(0,0,0,.9) 0%, rgba(0,0,0,0) 66%);
        min-height: 480px;
    }
}

.landing-hero--nav {
    /* Two lines of nav items */
    @include media-breakpoint-up(lg) {
        padding-bottom: 245px;
    }
    /* One line of nav items */
    @media screen and (min-width: $nav-item-breakpoint) {
        padding-bottom: 150px;
    }
}

.landing-hero__nav {
    @include media-breakpoint-up(lg) {
        position: absolute;
        width: 100%;
        margin-top: -245px;
    }
    @media screen and (min-width: $nav-item-breakpoint) {
        margin-top: -150px;
    }
}

.landing-hero-placeholder {
    min-height: 200px;
}

.landing-hero-about-us {
    h1 {
        @extend .h3;
        @include media-breakpoint-up(lg) {
            font-size: 2rem;
            line-height: 1.2;
        }
    }
    @media screen and (min-width: 1441px) {
        .landing-hero__background {
            min-height: 650px;
        }
        .landing-hero__gradient {
            min-height: 650px;
        }
    }
    @media screen and (min-width: 2025px) {
        .landing-hero__background {
            min-height: 750px;
        }
        .landing-hero__gradient {
            min-height: 750px;
        }
    }
}

.landing-hero-support,
.landing-hero-personal {
    .landing-hero--nav {
        /* Two lines of nav items */
        @include media-breakpoint-up(lg) {
            padding-bottom: 210px;
        }
        /* One line of nav items */
        @media screen and (min-width: 1580px) {
            padding-bottom: 150px;
        }
    }

    .landing-hero__nav {
        @include media-breakpoint-up(lg) {
            position: absolute;
            width: 100%;
            margin-top: -210px;
        }
        @media screen and (min-width: 1580px) {
            margin-top: -200px;
        }
        @media screen and (min-width: 1870px) {
            margin-top: -150px;
        }
    }
}
