.hero-lifestyle {
  //margin-top: 50px;
}

.hero-lifestyle-container {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr .75fr .2fr .2fr .4fr;
  grid-template-areas:
    "title"
    "imageTop"
    "imageMid"
    "imageBottom"
    "content";
  justify-items: center;

  @include media-breakpoint-up(lg) {
    grid-template-columns: .25fr .6fr 1fr .25fr;
    grid-template-rows: 1fr 3fr 2fr;
    grid-template-areas:
    "gutterLeft top top gutterRight"
    "gutterLeft title image gutterRight"
    "gutterLeft content image gutterRight";
  }

  .breadcrumb {
    grid-area: title;
    justify-self: start;
    z-index: 100;
    margin-left: .85em;
    margin-right: 0;
    margin-top: .85em;

    li {
      color: #fff;
      text-shadow: 2px 3px 5px rgba(0,0,0,0.3);

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    @include media-breakpoint-up(lg) {
      grid-area: top;
      margin-top: 2em;
      margin-left: 0;

    }
  }
}

.hero-lifestyle-title {
  grid-area: title;
  z-index: 100;
  padding-left: 20px;
  padding-right: 15vw;
  padding-bottom: 20px;
  align-self: flex-end;

  @include media-breakpoint-up(md) {
    padding-right: 25vw;
  }

  @include media-breakpoint-up(lg) {
    grid-area: title;
    align-self: end;
    padding-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
  }

  h1 {
    font-size: 24px;
    line-height: 120%; /* 28.8px */
    letter-spacing: 1.2px;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      line-height: 120%; /* 57.6px */
      letter-spacing: 2.4px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 42px;
    }
  }
}

.hero-lifestyle-background-image {
  grid-column: title;
  grid-row: title / imageTop;
  img {
    object-fit: cover;
    object-position: top;
  }
  @include media-breakpoint-up(sm) {
    img {
      object-fit: cover;
    }
  }
  @include media-breakpoint-up(lg) {
    grid-column: gutterLeft / span 4;
    grid-row: gutterRight / span 2;

    img {
      object-fit: cover;
    }
  }
}

.hero-lifestyle-body {
  grid-area: content;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  p {
    font-size: 18px;
  }

  @include media-breakpoint-up(lg) {
    text-align: left;
    padding-top: 45px;
    padding-right: 0;
    padding-left: 0;
  }
}

.hero-lifestyle-figure {
  grid-column: imageTop;
  grid-row: imageTop / imageBottom;
  z-index: 100;
  align-self: flex-end;
  padding: 0 20px;
  max-width: 350px;
  @include media-breakpoint-up(sm) {
    max-width: 500px;
  }
  @include media-breakpoint-up(md) {
    align-self: center;
  }
  @include media-breakpoint-up(lg) {
    grid-area: image;
    padding: 0;
    max-width: none;
  }
  @include media-breakpoint-up(xxl) {
    align-self: flex-end;
    padding-top: 0;
    max-width: 800px;
  }
}

.hero-lifestyle-simple {
  display: grid;
  position: relative;
  height: 60vh;
  width: 100%;
  color: white;
  overflow: hidden;
}

.hero-lifestyle-simple img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  //object-position: center top;
  z-index: 1;
}

.hero-lifestyle-simple-content {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  padding: 1.5rem 2.5rem;
  margin: 1rem;
  max-width: 650px;
  width: 45vw;
  align-self: end;
  justify-self: end;
  text-align: left;
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hero-lifestyle-simple-content p {
  font-size: 1.125rem;
}

@media (max-width: 991px) {
  .hero-lifestyle-simple {
    height: auto;
    //padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .hero-lifestyle-simple img {
    position: static;
  }

  .hero-lifestyle-simple-content {
    background: transparent;
    justify-self: flex-start;
    align-self: flex-start;
    text-align: left;
    width: 100%;
    max-width: none;
    color: $body-color;
    padding: 0;
    margin: 1rem 0 0 0;
  }
}

.hero-lifestyle-simple-content-left {
  justify-self: start;
  //align-self: center;
  margin-bottom: 5vh;

  .container-fluid & {
    @include media-breakpoint-up(xxl) {
      margin-left: 8vw;
      max-width: 600px;
    }
  }
}

.hero-lifestyle-simple-content-no-screen {
  background: none;
}

