$button-variants: (
    default: blue,
    red: red,
    green: green,
    yellow: yellow
);
$categoryHeight: 600px;
$numberOfCategories: 5;

//time between categories starting their animation
$delayIncrement: 420ms;
//speed of animation of individual products
$animationSpeed: 1700ms;

/* How product categories (top sellers) animation works:
* To begin with, container has 'initial' class that sets up special behavior for initial animation
* When it comes into view, 'in-view' class triggers cascading transitions where products slide from 
* off the screen on the left to off the screen on the right, except for one category that stays on screen.
* When a category is clicked, 'initial' is removed and a simpler animation brings the open category from
* off-screen to its natual middle position
*/

//desktop background
.product-category-background {
    @include media-breakpoint-up(lg) {
        background-image: url('#{$cdnPath}/img/category-bg.png');
        background-repeat: repeat-x;
        background-position: 0 60%;
    }
}

//mobile background
.product-category-container {
    @include media-breakpoint-down(md) {
        background-image: url('#{$cdnPath}/img/category-bg.png');
        background-repeat: repeat-x;
        background-position: bottom left;
        background-size: 100% 60%;
    }
}

//Technically all categories are always on the page, but "hidden" when not open

.product-category-title {
    opacity: 0;
    transition: opacity 1s;
    max-width: 450px;
    margin: auto;
    font-size: 2rem;
    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }
}
.product-category-button {
    opacity: 0;
    transition: opacity 1s;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        position: absolute;
        bottom: 75px;
        left: 0;
        right: 0;
    }
}

.product-category-link {
    display: none;
    position: absolute;
    bottom: 10px;
    width: 100%;
    letter-spacing: 2px;
    font-size: .9rem;
}

.product-category-product {
    position: relative;
    max-width: 325px;
}

.product-category-product-image {
    img {
        max-width: 323px;
        margin-left: auto;
        margin-right: auto;
    }
}

.product-category-product-hover {
    display: none;
    width: 325px;
    margin-left: auto;
    margin-right: auto;
    img {
        display: block;
        //height: 412px;
        max-width: 100%;
    }
    &:after {
        content: " ";
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(83,17,17,0) 45%,rgba(185,38,38,1) 100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    & ~ .product-category-link .btn-tertiary {
        color: #fff;
        &:hover {
            color: #000;
        }
    }
}

.initial .product-category .col-3 {
    //For the initial animation (happens when in-view is on the container) we want products to come from left to right, so negative starting value
    left: -120%;
}

.product-category {
    position: relative;
    overflow: hidden;
    height: 0;
    border: 1px solid #000;
    .col-3 {
        position: relative;
        transition: left ($animationSpeed / 2);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        //Each individual item starts 120% off the page and then slides to the middle (0). They should always come right to left, so positive number.
        left: 120%;

        @media (prefers-reduced-motion) {
            transition: none;
        }

        //On desktop there's a hover state that fills the whole column
        @include media-breakpoint-up(lg) {
            height: 412px; //height of tallest possible product 
            &:hover, &:focus {
                outline: none;
               .product-category-product {
                    background-color: #E5E5E5;
                    // display: flex;
                    // flex-direction: column;
                    justify-content: space-between;
                    outline: none;
                    .product-category-name {
                        display: none;
                    }
                    .product-category-link {
                        display: block;
                    }
                    .product-category-product-hover {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
    //When category is open, show its elements
    &[data-category-open=true] {
        //Mobile height cuts off the scrollbar
        height: 520px;
        z-index: 5;
        .product-category-title {
            opacity: 1;
        }
        .product-category-button {
            opacity: 1;
        }
        .col-3 {
            left: 0;
        }
        @include media-breakpoint-up(lg) {
            height: $categoryHeight;
        }
    }

    @include media-breakpoint-up(lg) {
        border: none;
        .in-view.categories-animation & {
            height: $categoryHeight; 
            .product-category-title {
                opacity: 0;
            }
        }
    }
}

//Last (first-appearing) section is on screen to begin with
.initial .product-category-container:last-child .product-category {
    @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    width: 100%;
    .product-category-button, .product-category-subtitle {
        opacity: 0;
    }
    .product-category-title {
        opacity: 1;
    }
    .col-3 {
        left: 0;
    }
    
        height: $categoryHeight;
    }
}

.categories-animation {
    position: relative;
    z-index: 2;
}

//Animation on to the screen for all sections but the final appearing section
@for $i from 0 through ($numberOfCategories - 2) {
    .in-view.categories-animation .product-category-container:nth-child(#{$numberOfCategories - $i}) .product-category {
        position: absolute;
        top: 0;
        width: 100%;
        .product-category-button, .product-category-title, .product-category-subtitle {
            opacity: 0;
        }
        .col-3 {
            left: 150%;
            transition-delay: $delayIncrement * $i;
        }
    }
}

//Preparing the first (final appearing) section for the animation
.categories-animation .product-category-container:nth-child(1) .product-category {
    @include media-breakpoint-up(lg) {
        h3, .btn {
            opacity: 0;
        }
        .col-3 {
            left: -120%;
            //faster because it's moving half as far (from -120% to 0 instead of -120% to 120%)
            transition: left ($animationSpeed / 2);
        }
    }
}

//The animation for the first (final appearing) section (different because it stays on screen)
.in-view.categories-animation .product-category-container:nth-child(1) .product-category {
    .product-category-title, .product-category-button {
        opacity: 1;
        transition-delay: 150ms + ($delayIncrement * ($numberOfCategories - 1));
    }
    .col-3 {
        left: 0;
        &:nth-child(1) {
            transition-delay: ($delayIncrement * ($numberOfCategories - 1));
        }
        &:nth-child(2) {
            transition-delay: ($delayIncrement * ($numberOfCategories - 1)) + 50ms;
        }
        &:nth-child(3) {
            transition-delay: ($delayIncrement * ($numberOfCategories - 1)) + 100ms;
        }
        &:nth-child(4) {
            transition-delay: ($delayIncrement * ($numberOfCategories - 1)) + 150ms;
        }
    }
}

.product-category-grid {
    height: 365px; 
    @include media-breakpoint-up(lg) {
        height: auto;
        padding-bottom: 30px; 
    }
}

.product-category-product {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.product-category-product {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    img {
        display: block;
        max-width: 323px;
        margin: auto;
    }
}

.product-category-name {
    position: relative;
    text-transform: uppercase;
    margin-top: 5px;
    padding-bottom: 20px;
}

.product-category-name:after {
    content: url('#{$cdnPath}/img/shadow.png');
    position: absolute;
    bottom: -50px;
    left: 50%;
    margin-left: -100px;
}

.categories__container {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    align-items: stretch;
    border: 1px solid #000;
    overflow: visible;
}

.categories__inner-container {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
}

.categories__arrow {
    background: $dark;
    border-radius: 0;
    color: #fff;
    display: block;
    border: none;
    //Previous
    &:first-child {
        border-right: 5px solid #fff;
    }
    //Next
    &:last-child {
        border-left: 5px solid #fff;
        img {
            transform: rotate(180deg);
        }
    }
    &:focus {
        outline: none;
        border-color: $featured;
    }
}

.categories__button {
    padding: 20px 15px 12px 15px;
    border: none;
    background: none;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    max-width: 220px;
    line-height: 1.1rem;
    letter-spacing: 2px;
    color: #000;
    cursor: pointer;
    &:focus {
        outline: none;
        &:after {
            content: ' ';
            width: 100%;
            position: absolute;
            border-bottom: 5px solid $gray;
            bottom: -2px;
            z-index: 2;
            left: 0;
        }
    }
    &[aria-expanded=true]:after, &[aria-expanded=true]:focus:after, &:hover:after {
        content: ' ';
        width: 100%;
        position: absolute;
        border-bottom: 5px solid $featured;
        bottom: -2px;
        z-index: 2;
        left: 0;
    }
}

//On initial animate, slide the "open" category indicator
.categories-animation .categories__button[aria-expanded=true]:after {
    left: 800px;
    transition: left (($delayIncrement * $numberOfCategories) + 500ms) linear;
}

.categories-animation .categories__container--timeline .categories__button[aria-expanded=true]:after {
    left: 1020px;
    transition: left (($delayIncrement * $numberOfCategories + 1) + 500ms) linear;
}

.in-view.categories-animation .categories__button[aria-expanded=true]:after {
    left: 0;
}