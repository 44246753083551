.toggle-button {
    border: none;
    background: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    overflow-anchor: none;
    &:focus {
        outline: none;
        &:after {
            outline: 2px solid $featured;
        }
    }
    &:after {
        content: " ";
        background-image: url('#{$cdnPath}/img/down-arrow-small.svg');
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        margin: auto;
        width: 30px;
        height: 20px;
        background-color: #000;
    }
    &[aria-expanded=true] {
        .toggle-button__text--hide {
            display: block;
        }
        &:after {
            transform: rotate(180deg);
        }
    }
    &[aria-expanded=false] {
        .toggle-button__text--show {
            display: block;
        }
    }
}

.toggle-button__text {
    display: none;
}

.toggle-button-container {
    background-image: url(#{$cdnPath}/img/drawer-bg-large.png);
    background-position: right center;
    background-repeat: no-repeat;
}