.coupon-flag {
  .coupon-body,
  .coupon-code {
    float: left;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffce00;
    font-weight: bold;
    color: $body-color;
    padding: 8px 15px;
    font-size: 11px;
    letter-spacing: 0;
  }
  .coupon-body {
    width: 170px;
    text-transform: none;
    border-right: 1px dashed #995200;
    border-radius: 0 5px 5px 0;
    font-size: 12px;
    font-weight: 700;
  }
  .coupon-code {
    border-radius: 5px 0 0 5px;
    font-size: 14px;
  }
}