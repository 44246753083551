$carouselTransitionCurve: cubic-bezier(0.165, 0.84, 0.44, 1);
$carouselTransitionDuration: 1400ms;

.carousel-fancy {
  --image-offset: 35vw;

  img {
    max-height: 25vh;

    @include media-breakpoint-up(sm) {
      max-height: 50vh;
    }

    @include media-breakpoint-up(lg) {
      padding-left: var(--image-offset);
      padding-right: 4vw;
      transition: all $carouselTransitionDuration $carouselTransitionCurve;
      opacity: 1;
      transform: scale(1);
      max-height: 70vh;
    }
  }


  .carousel-fancy-panel {
    max-width: 90vw;
    margin: 0 auto 20px;

    @include media-breakpoint-up(lg) {
      max-width: 550px;
      background: white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8vw;
      margin-top: 20px;
    }
  }

  .carousel-fancy-body {
    transition: all $carouselTransitionDuration $carouselTransitionCurve;
    position: relative;
    //height: 55vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    p {
      line-height:1.6;
    }

    @include media-breakpoint-up(sm) {
      //aspect-ratio: 508/200;
      //height: auto;
    }

    @include media-breakpoint-up(lg) {
      //aspect-ratio: 508/300;
    }

    @media screen and (max-height: 650px) and (max-width: 400px) {
      //height: 80vh;
    }

    div[data-carousel-body] {
      transition: all $carouselTransitionDuration $carouselTransitionCurve;
      flex: 1 0 100%;

      //p {
      //  margin-bottom: 0;
      //}
    }
  }

  .is-prev {
    img {
      opacity: 0;
      transform: scale(.7);
    }
  }
  .is-next {
    img {
      opacity: 0;
      transform: scale(.7);
    }
  }
  .is-active {
    img {
      opacity: 1;
      transform: scale(1);
    }

  }

  .carousel-controls-text {
    font-family: $headings-font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.56px;
    text-transform: uppercase;
    display: block;
    padding: 0 10px;

    span {
      display: block;
    }
  }

  .glide__track,
  .glide__slides {
    //overflow: visible;
  }

  .glide__slides {
    align-items: center;
  }

  .glide__arrow {
    border: none;
    line-height: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .glide__arrow:focus {
    border: none;
    outline: none;
  }

  .glide__bullets {
    display: flex;
    gap: 4px;
  }

  .glide__bullet {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    border: 2px solid #000;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .glide__bullet--active {
    background-color: #000;
  }

  .glide__arrow--disabled {
    opacity: .2;
  }

  &--full-width {
    --image-offset: 4vw;
    .carousel-fancy-panel {
      max-width: 90vw;
      margin: 20px auto;

      @include media-breakpoint-up(lg) {
        max-width: 550px;
        background: white;
        position: absolute;
        top: 80%;
        transform: translateY(-50%);
        left: 8vw;
      }
    }
  }
}